import {
  ChoiceGroup,
  CommandBar,
  DefaultButton,
  DetailsListLayoutMode,
  FontIcon,
  ICommandBarItemProps,
  IStackTokens,
  mergeStyles,
  mergeStyleSets,
  PrimaryButton,
  Separator,
  ShimmeredDetailsList,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { useContext, useEffect, useState } from "react";
import {
  getImportAssets,
  IImportedAsset,
  IImportResult,
  ILastImport,
  importAssets,
  previewImportAssets,
} from "../../services/assetServices";
import { downloadFile } from '../../common/DownloadFile';
import AppContext from "../AppContext";
import ImportLogs from "./ImportLogs";
import { useSetAtom } from "jotai";
import { isInProgressAtom } from "../../atoms/messageBarAtoms";
import { RenderIf } from "../../libs";

const ImportAsset = () => {
  const importDialogContentProps = {
    type: DialogType.normal,
    title: "Initiating import",
    closeButtonAriaLabel: "Close",
  };
  const setIsInProgress = useSetAtom(isInProgressAtom);

  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);

  const [message, setMessage] = useState("");

  const [importedAssets, setImportedAssets] = useState<IImportedAsset[]>([]);

  const [shimmered, setShimmered] = useState(false);
  const [showImportedDataList, setShowImportedDataList] = useState(false);
  const [logFile, setLogFile] = useState("");

  const [importData, setImportData] = useState({
    lastImport: { lastImportedDateTime: "", lastImportedId: "", createdAt: "" },
    latestImportedAsset: { code: "", changeDate: "" },
    latestAssetInSourceDate: "",
    latestAssetInSourceNum: "",
  });

  useEffect(() => {
    const abortController = new AbortController();

    setIsInProgress(true);
    setMessage("Fetching import history");
    getImportAssets(abortController)
      .then((data: ILastImport) => {
        setMessage("");
        setImportData(data);
        setIsInProgress(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setMessage(error.Message);
        setIsInProgress(false);
      });

    return () => {
      abortController.abort();
    }
  }, [importData?.latestAssetInSourceDate]);

  const stackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10,
  };

  const handlePreviewImportClick = () => {
    setShowImportedDataList(true);
    setShimmered(true);
    const abortController = new AbortController();

    previewImportAssets(abortController)
      .then((data: IImportedAsset[]) => {
        const message = `${data.length} assets to be imported`;
        setMessage(message);
        setImportedAssets(data);
        setShimmered(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setShowImportedDataList(false);
        setMessage(error.Message);
      });
  };

  const handleImportClick = () => {
    setShowImportedDataList(true);
    setShimmered(true);
    const abortController = new AbortController();

    importAssets(abortController, clearChoice, seed)
      .then((data: IImportResult) => {
        const message = `${data.importedItems.length} items imported`;
        setMessage(message);
        setImportedAssets(data.importedItems);
        setLogFile(data.logFilePath);
        setShimmered(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setShowImportedDataList(false);
        setMessage(error.Message);
      });
    setIsImportDialogOpen(false);
  };

  const columns = [
    {
      key: "Id",
      name: "Id",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 50,
      isResizable: true,
    },
    {
      key: "Code",
      name: "Code",
      fieldName: "code",
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: "Date",
      name: "Date",
      fieldName: "changedDate",
      minWidth: 150,
      maxWidth: 175,
      isResizable: true,
    },
  ];

  const [clearChoice, setClearChoice] = useState(false);
  const [seed, setSeed] = useState(1);

  const optionRootClass = mergeStyles({
    display: "flex",
    alignItems: "center",
    gap: "5px",
  });
  const warningIconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    margin: "0 10px",
    color: "red",
  });
  const classNames = mergeStyleSets({
    dataListContainer: {
      height: 500,
      overflow: 'auto',
    },
  });

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: 'logFile',
      iconOnly: true,
      text: 'Show Log File',
      iconProps: { iconName: 'DownloadDocument' },
      disabled: !logFile,
      onClick: () => {
        try {
          setIsInProgress(true);
          downloadFile(logFile);
        } finally {
          setIsInProgress(false);
        }
      }
    }
  ]

  return (
    <>
      <Dialog
        hidden={!isImportDialogOpen}
        onDismiss={() => setIsImportDialogOpen(false)}
        dialogContentProps={importDialogContentProps}
      >
        <Stack horizontal>
          <ChoiceGroup
            label="Clear existing assets"
            defaultSelectedKey="no"
            options={[
              {
                key: "yes",
                text: "Yes",
                styles: { root: { border: "1px solid red" } },
                onRenderField: (props, render) => {
                  return (
                    <div className={optionRootClass}>
                      {render!(props)}
                      <FontIcon
                        aria-label="Train"
                        iconName="WarningSolid"
                        className={warningIconClass}
                      />
                    </div>
                  );
                },
              },
              { key: "no", text: "No" },
            ]}
            onChange={(ev, option) => {
              setClearChoice(option?.key === "yes");
            }}
          />
        </Stack>
        <TextField
          label="Starting number"
          disabled={!clearChoice}
          onChange={(ev, newValue) => {
            if (newValue) {
              setSeed(parseInt(newValue));
            }
          }}
        />

        <DialogFooter>
          <PrimaryButton onClick={() => handleImportClick()} text="Yes" />
          <DefaultButton
            onClick={() => setIsImportDialogOpen(false)}
            text="No"
          />
        </DialogFooter>
      </Dialog>

      <Stack tokens={stackTokens}>
        {message ? <div>{message}</div> : ""}
        <Separator alignContent='start'>
          <Text variant="large">Last import</Text>
        </Separator>
        <RenderIf condition={!!importData}>
          <Stack horizontal>
            <Text>Import date: </Text>
            <Text>{importData ? (new Date(importData.lastImport?.createdAt + 'Z').toLocaleString()) : ""}</Text>
          </Stack>
          <Stack horizontal>
            <Text>Code of latest imported asset: </Text>
            <Text>{importData ? importData.latestImportedAsset?.code : ""}</Text>
          </Stack>
          <Stack horizontal>
            <Text>Date of latest imported asset: </Text>
            <Text>{importData ? new Date(importData.latestImportedAsset?.changeDate + 'Z').toLocaleString() : ""}</Text>
          </Stack>
        </RenderIf>
        <RenderIf condition={!importData}>
          <Text>No previous import</Text>
        </RenderIf>
        <Separator alignContent='start'>
          <Text variant="large">Source</Text>
        </Separator>
        <Stack horizontal>
          <Text>Latest asset date: </Text>
          <Text>{importData ? new Date(importData.latestAssetInSourceDate + 'Z').toLocaleString() : ''}</Text>
        </Stack>
        <Stack horizontal>
          <Text>Latest asset code: </Text>
          <Text>{importData ? importData.latestAssetInSourceNum : ''}</Text>
        </Stack>
        <Stack horizontal>
          <DefaultButton onClick={() => handlePreviewImportClick()}>
            Preview import
          </DefaultButton>
          <PrimaryButton onClick={() => setIsImportDialogOpen(true)}>
            Start import
          </PrimaryButton>
        </Stack>
        <Separator alignContent='start'>
          <Text variant="large">Import logs</Text>
        </Separator>
        <CommandBar items={commandBarItems}></CommandBar>
        <ImportLogs></ImportLogs>
        {showImportedDataList ? (
          <div className={classNames.dataListContainer} >
            <ShimmeredDetailsList
              items={importedAssets}
              columns={columns}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
              enableShimmer={shimmered}
            />
          </div>
        ) : (
          ""
        )}
      </Stack>
    </>
  );
};

export default ImportAsset;
