import { Dropdown, Stack, CommandBar, ICommandBarItemProps, TextField, Label, DialogFooter, DefaultButton, Modal, DetailsList, Text, IconButton, ITextField, IDropdown, FontIcon, Checkbox, ICheckbox, mergeStyles, getTheme, Pivot, PivotItem, Panel, PanelType } from '@fluentui/react';
import { useBoolean } from "@fluentui/react-hooks";
import { IDragDropEvents, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { useContext, useEffect, useRef, useState } from 'react'
import Editor from 'react-simple-code-editor';
import Prism from "prismjs";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.min.css";
import { getMetadata, IField, ILookup, IValidation, ILookupValue, IMetadata, IUpdateMetadataResponse, updateMetadata, getReferences } from '../../services/assetServices';
import ConfirmDialog from '../../common/ConfirmDialog';
import { useSetAtom } from 'jotai';
import { errorMessageAtom, isInProgressAtom, successMessageAtom } from '../../atoms/messageBarAtoms';
import ValidationEditor from './ValidationEditor';
import AppContext from '../AppContext';

export const Metadata = () => {

  const [metadata, setMetadata] = useState<IMetadata>();
  const [metadataStr, setMetadataStr] = useState("");
  const [origMetadataStr, setOrigMetadataStr] = useState("");
  const [entityType, setEntityType] = useState("");
  const [name, setName] = useState("");
  const [currFieldIndex, setCurrFieldIndex] = useState<number>();
  const [references, setReferences] = useState<string[]>([]);

  const setSuccessMessage = useSetAtom(successMessageAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);

  const context = useContext(AppContext);

  const fetchData = async () => {
    context.setSelectedTab(entityType !== "" ? `${entityType} metadata` : 'Metadata');
    const abortController = new AbortController();
    try {
      setIsInProgress(true);
      const references = await getReferences(abortController);
      setReferences(references);
    } finally {
      setIsInProgress(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [context.selectedTab])

  const fetchEntityTypeData = async (entityType: string) => {
    context.setSelectedTab(`${entityType} metadata`);

    const abortController = new AbortController();
    try {
      setIsInProgress(true)
      getMetadata(abortController, entityType)
        .then((data) => {
          setMetadata(data);
          setMetadataStr(JSON.stringify(data, null, 2));
          setOrigMetadataStr(JSON.stringify(data, null, 2));
          setSelectedLookup(undefined);
        })
    } finally {
      setIsInProgress(false);
    }
  }
  const _items: ICommandBarItemProps[] = [
    {
      key: 'entityType',
      onRender: () => (
        <Stack horizontal verticalAlign='center'>
          <Label style={{ paddingRight: 14 }}>Entity</Label>
          <Dropdown style={{ width: 150 }}
            options={[
              { key: 'Asset', text: 'Asset' },
              { key: 'Issue', text: 'Issue' },
              { key: 'WorkOrder', text: 'WorkOrder' },
              { key: 'Contractor', text: 'Contractor' },
              { key: 'User', text: 'User' },
              { key: 'Group', text: 'Group' },
            ]}
            onChange={(ev, option) => {
              setEntityType(option?.key.toString() ?? '');
              setSidePanel(undefined);
              fetchEntityTypeData(option?.key.toString() ?? '');
            }}
          />
        </Stack>
      )
    },
    {
      key: 'name',
      onRender: () => (
        <Stack horizontal verticalAlign='center'>
          <Label style={{ paddingRight: 14 }}>Name</Label>
          <TextField onChange={(e, newValue) => setName(newValue ?? '')} />
        </Stack>)
    },
    {
      key: 'save',
      name: 'Save',
      iconProps: { iconName: "Save" },
      disabled: metadataStr === origMetadataStr,
      onClick: () => {
        if (metadata) {
          try {
            setIsInProgress(true);
            const abortController = new AbortController();
            updateMetadata(abortController, entityType, metadata, name)
              .then((data: IUpdateMetadataResponse) => setSuccessMessage(`Version: ${data.version.toString()}`))
              .catch((err) => setErrorMessage(err.message));
          } finally {
            setIsInProgress(false);
          }
        }
      }
    },
    {
      key: 'show',
      name: 'Show',
      iconProps: { iconName: "Code" },
      onClick: () => {
        setMetadataStr(JSON.stringify(metadata, null, 2));
        showCodeDialog();
      }
    },
    {
      key: 'refresh',
      name: 'Refresh',
      iconProps: { iconName: 'Refresh' },
      onClick: () => {
        if (metadataStr !== origMetadataStr) {
          showConfirmDialog();
        }
      },
    }
  ]

  const [isCodeDialogOpen, { setTrue: showCodeDialog, setFalse: hideCodeDialog }] = useBoolean(false);
  const [isConfirmDialogOpen, { setTrue: showConfirmDialog, setFalse: hideConfirmDialog }] = useBoolean(false);
  const [sidePanel, setSidePanel] = useState<'LookupEditor' | 'FieldEditor' | 'ValidationEditor' | undefined>();

  const [selectedLookup, setSelectedLookup] = useState<ILookup>();
  const [selectedValidation, setSelectedValidation] = useState<IValidation>();
  const [lookupAddOrEdit, setLookupAddOrEdit] = useState<string | undefined>();
  const [editingLookupField, setEditingLookupField] = useState(false);
  const [tempField, setTempField] = useState<IField>();
  const [validationAddOrEdit, setValidationAddOrEdit] = useState<string | undefined>();
  const [editingValidationField, setEditingValidationField] = useState(false);

  const LookupEditor = () => {
    const [currLookupValueKey, setCurrLookupValueKey] = useState("");
    const [currLookupValueValue, setCurrLookupValueValue] = useState("");
    const [index, setIndex] = useState<number | undefined>();
    const [lookupValueAddOrEdit, setLookupValueAddOrEdit] = useState<string | undefined>();
    const currentLookupNameFieldRef = useRef<ITextField>(null);

    interface ILookupValueWithIndex extends ILookupValue {
      index: number;
    }

    return (
      <Stack>
        <CommandBar style={{ width: '100%' }}
          items={[
            {
              key: 'LookupName',
              onRender: () => (
                <Text variant='large'>{selectedLookup?.name}</Text>
              )
            },
            {
              key: 'AddLookup',
              onRender: () => (
                <IconButton iconProps={{ iconName: 'Add' }} onClick={() => {
                  setLookupValueAddOrEdit('add');
                  // setEditingLookupField(true);
                }} />
              )
            }
          ]}
        />
        <Stack horizontal>
          <Label style={{ width: 75 }}>Name</Label>
          <TextField defaultValue={selectedLookup?.name} readOnly={!editingLookupField} borderless={!editingLookupField}
            componentRef={currentLookupNameFieldRef} />
          <IconButton iconProps={{ iconName: "Rename" }} style={{ display: editingLookupField ? 'none' : '' }}
            onClick={() => setEditingLookupField(!editingLookupField)} />
          <IconButton iconProps={{ iconName: 'CheckMark' }} style={{ display: editingLookupField ? '' : 'none' }} onClick={() => {
            const newMetadata = { ...metadata, lookups: metadata?.lookups ?? [], fields: metadata?.fields ?? [], validations: metadata?.validations ?? [] };
            if (selectedLookup) {
              const editedLookup = { ...selectedLookup };
              editedLookup.name = currentLookupNameFieldRef.current?.value ?? '';
              setSelectedLookup(editedLookup);
              const lookupIndex = metadata?.lookups?.findIndex(l => l.name === selectedLookup.name);
              if (lookupIndex !== undefined) {
                newMetadata.lookups[lookupIndex] = editedLookup;
                setMetadata(newMetadata);
                setMetadataStr(JSON.stringify(newMetadata, null, 2));
              }
            } else if (lookupAddOrEdit === 'add') {
              const newLookup = { name: currentLookupNameFieldRef.current?.value ?? '', values: [] };
              newMetadata.lookups.push(newLookup);
              setMetadata(newMetadata);
              setMetadataStr(JSON.stringify(newMetadata, null, 2));
              setSelectedLookup(newLookup);
              setLookupAddOrEdit('edit');
            }
            setEditingLookupField(false);
          }} />
          <IconButton iconProps={{ iconName: 'Cancel' }} style={{ display: editingLookupField ? '' : 'none' }} onClick={() => {
            setEditingLookupField(!editingLookupField)
          }} />
        </Stack>
        <DetailsList
          items={selectedLookup?.values?.map((v, index) => ({ key: v.key, value: v.value, index: index })) ?? []}
          selectionMode={SelectionMode.none}
          columns={[
            {
              key: 'key',
              name: 'Key',
              fieldName: 'key',
              minWidth: 45,
            },
            {
              key: 'value',
              name: 'Value',
              fieldName: 'value',
              minWidth: 75,
            },
            {
              key: 'action',
              name: 'Action',
              minWidth: 70,
              onRender: (item: ILookupValueWithIndex) => (
                <Stack horizontal>
                  <IconButton iconProps={{ iconName: "Edit" }} onClick={() => {
                    setLookupValueAddOrEdit('edit');
                    setCurrLookupValueKey(item.key);
                    setCurrLookupValueValue(item.value);
                    setIndex(item.index);
                  }} />
                  <IconButton iconProps={{ iconName: "Delete" }} onClick={() => {
                    if (selectedLookup) {
                      const newLookup = { ...selectedLookup };
                      newLookup.values = selectedLookup?.values.filter(l => l.key !== item.key);
                      setSelectedLookup(newLookup);
                      const newMetadata = { ...metadata, lookups: metadata?.lookups ?? [], fields: metadata?.fields ?? [], validations: metadata?.validations ?? [] };
                      const lookupIndex = metadata?.lookups?.findIndex(l => l.name === selectedLookup.name);
                      if (lookupIndex !== undefined) {
                        newMetadata.lookups[lookupIndex] = newLookup;
                        setMetadata(newMetadata);
                        setMetadataStr(JSON.stringify(newMetadata, null, 2));
                      }
                    }
                  }} />
                </Stack>
              )
            },
          ]}
        />
        <Stack horizontal style={{ display: lookupValueAddOrEdit !== undefined ? '' : 'none' }} >
          <TextField width='100px' value={currLookupValueKey} onChange={(ev, newValue) => setCurrLookupValueKey(newValue ?? '')} />
          <TextField width='100px' value={currLookupValueValue} onChange={(ev, newValue) => setCurrLookupValueValue(newValue ?? '')} />
          <IconButton iconProps={{ iconName: 'CheckMark' }} onClick={() => {
            const lookups = metadata?.lookups.filter(lookup => lookup.name !== selectedLookup?.name);
            const currLookup = selectedLookup;
            if (lookupValueAddOrEdit === 'add') {
              currLookup?.values?.push({ key: currLookupValueKey, value: currLookupValueValue });
            } else if (lookupValueAddOrEdit === 'edit' && (index !== undefined)) {
              const newLookupValues = currLookup?.values.slice(0, index);
              newLookupValues?.push({ key: currLookupValueKey, value: currLookupValueValue });
              currLookup?.values.slice(index + 1)?.map(v => newLookupValues?.push(v));
              if (currLookup) {
                currLookup.values = [...newLookupValues ?? []];
              }
            }
            if (currLookup && lookups) {
              lookups?.push(currLookup);
              setSelectedLookup(currLookup);
              const newMetadata = {
                ...metadata,
                lookups: [...lookups], fields: [...metadata?.fields ?? []], validations: [...metadata?.validations ?? []]
              };
              setMetadata(newMetadata);
              setMetadataStr(JSON.stringify(newMetadata, null, 2));
            }
            setCurrLookupValueKey("");
            setCurrLookupValueValue("");
            setLookupValueAddOrEdit(undefined);
          }} />
          <IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => { setLookupValueAddOrEdit(undefined) }} />
        </Stack>
      </Stack>
    )
  }

  const FieldEditor = () => {
    const fieldNameRef = useRef<ITextField>(null);
    const fieldLabelRef = useRef<ITextField>(null);
    const fieldTypeRef = useRef<IDropdown>(null);
    const fieldLookupRef = useRef<IDropdown>(null);
    const fieldReferencesRef = useRef<IDropdown>(null);
    const fieldDependeesRef = useRef<IDropdown>(null);

    const fieldShowInGridRef = useRef<ICheckbox>(null);
    const [fieldError, setFieldError] = useState<string>();
    const [fieldType, setFieldType] = useState(tempField?.fieldType);
    return (
      <Stack style={{ width: '100%' }}>
        <CommandBar
          items={[
            {
              key: 'FieldName',
              onRender: () => (
                <Text variant='large'>Field properties</Text>
              )
            },
          ]}
        />
        <TextField defaultValue={tempField?.name} label='Name' componentRef={fieldNameRef} />
        <Dropdown
          options={[
            { key: 'String', text: 'String' },
            { key: 'Number', text: 'Number' },
            { key: 'Lookup', text: 'Lookup' },
            { key: 'Image', text: 'Image' },
            { key: 'AutoComplete', text: 'AutoComplete Lookup' },
            { key: 'Date', text: 'Date' },
          ]}
          // defaultSelectedKey={tempField?.fieldType} 
          selectedKey={fieldType}
          label='fieldType'
          componentRef={fieldTypeRef}
          onChange={(ev, option) => {
            setFieldType(option?.key.toString() ?? '');
          }}
        />
        <TextField defaultValue={tempField?.label} label='label' componentRef={fieldLabelRef} />
        <Dropdown label='LookupList'
          options={metadata?.lookups?.map(l => ({ key: l.name, text: l.name })) ?? []}
          defaultSelectedKey={tempField?.lookupList}
          styles={{ root: { display: fieldType !== 'Lookup' ? 'none' : '' } }}
          componentRef={fieldLookupRef}
        />
        <Dropdown label='Reference name'
          options={references?.map(r => ({ key: r, text: r })) ?? []}
          defaultSelectedKey={tempField?.reference}
          styles={{ root: { display: fieldType !== 'AutoComplete' ? 'none' : '' } }}
          componentRef={fieldReferencesRef}
        />
        <Dropdown label='Dependees'
          options={metadata?.fields?.map(f => ({ key: f.name, text: f.name })) ?? []}
          defaultSelectedKeys={tempField?.dependees?.split('|')}
          multiSelect={true}
          styles={{ root: { display: fieldType !== 'AutoComplete' ? 'none' : '' } }}
          componentRef={fieldDependeesRef}
        />
        <Checkbox label='Show in grid' defaultChecked={tempField?.showInGrid} boxSide="end" componentRef={fieldShowInGridRef} />
        <Stack horizontal horizontalAlign='end'>
          <Text variant='medium' style={{ color: 'red' }}>{fieldError}</Text>
          <IconButton iconProps={{ iconName: 'CheckMark' }} onClick={() => {
            const selectedFieldType = fieldTypeRef.current?.selectedOptions[0].key.toString();
            const selectedFieldLookup = fieldLookupRef.current?.selectedOptions[0]?.key.toString();
            setFieldError(undefined);
            if (selectedFieldType === 'Lookup' && selectedFieldLookup === undefined) {
              setFieldError("Type selected as Lookup, but missing lookup");
              return;
            }
            if (metadata && tempField) {
              const fields = metadata?.fields;
              const newOrEditedField = {
                name: fieldNameRef.current?.value ?? '', label: fieldLabelRef.current?.value ?? '',
                fieldType: selectedFieldType ?? '', lookupList: selectedFieldLookup ?? '',
                dependees: fieldDependeesRef.current?.selectedOptions.map(o => o.key).join('|'),
                reference: fieldReferencesRef.current?.selectedOptions[0]?.key?.toString() ?? '',
                showInGrid: fieldShowInGridRef.current?.checked ?? false,
              };
              if (currFieldIndex !== undefined) {
                fields[currFieldIndex] = newOrEditedField
              } else {
                fields.push(newOrEditedField);
              }
              const newMetadata = {
                lookups: metadata.lookups ?? [], fields: [...metadata?.fields ?? []], validations: [...metadata?.validations ?? []]
              }
              setMetadata(newMetadata);
              setMetadataStr(JSON.stringify(newMetadata, null, 2));
            }
            setSidePanel(undefined);
          }} />
          <IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => {
            setCurrFieldIndex(undefined);
            setTempField(undefined);
            setSidePanel(undefined);
          }} />
        </Stack>
      </Stack>
    )
  }

  const LookupsEditor = () => {
    interface ILookupWithIndex extends ILookup {
      index: number;
    }

    return (
      <Stack>
        <CommandBar items={[
          {
            key: 'AddLookup',
            text: 'Add',
            iconProps: { iconName: 'Add' },
            onClick: () => {
              setLookupAddOrEdit('add');
              setEditingLookupField(false);
              setSidePanel('LookupEditor');
            },
          }
        ]}
        />
        <DetailsList
          columns={[
            {
              key: 'name',
              name: 'Name',
              fieldName: 'name',
              minWidth: 100
            },
            {
              key: 'action',
              name: 'Action',
              minWidth: 85,
              onRender: (item: ILookupWithIndex) => (
                <Stack horizontal>
                  <IconButton iconProps={{ iconName: "Edit" }} onClick={() => {
                    setSelectedLookup(item);
                    setSidePanel('LookupEditor');
                  }} />
                  <IconButton iconProps={{ iconName: "Delete" }} onClick={() => {
                    const newMetadata = {
                      ...metadata,
                      lookups: metadata?.lookups.filter(l => l.name !== item.name) ?? [], fields: metadata?.fields ?? [], validations: metadata?.validations ?? []
                    };
                    setMetadata(newMetadata);
                    setMetadataStr(JSON.stringify(newMetadata, null, 2));
                    setSelectedLookup(undefined);
                  }} />
                </Stack>
              )
            },
          ]}
          items={metadata?.lookups.map((l, index) => ({ ...l, index: index })) ?? []}
          selectionMode={SelectionMode.none}
        />
      </Stack>
    )
  }

  const FieldsEditor = () => {
    interface IFieldWithIndex extends IField {
      index: number;
    }

    let draggedItem: IField | undefined;
    let draggedIndex: number = -1;
    const insertBeforeItem = (item: IField): void => {

      const insertIndex = metadata?.fields?.findIndex(f => f.name === item.name) ?? -1;
      if (insertIndex === -1 || draggedItem === undefined) {
        return;
      }
      const newFields = metadata?.fields?.filter(f => f.name !== draggedItem?.name);
      newFields?.splice(insertIndex, 0, draggedItem);
      const newMetadata = { lookups: metadata?.lookups ?? [], fields: newFields ?? [], validations: metadata?.validations ?? [] }
      setMetadata(newMetadata);
      setMetadataStr(JSON.stringify(newMetadata, null, 2));
    }
    const theme = getTheme();
    const dragEnterClass = mergeStyles({
      backgroundColor: theme.palette.neutralLight,
      borderTopColor: 'black',
      borderTopStyle: 'solid',
    });
    const getDragDropEvents = () => {
      return {
        canDrop: (dropContext?: any, dragContext?: any) => {
          return true;
        },
        canDrag: (item?: any) => {
          return true;
        },
        onDrop: (item?: IField, event?: DragEvent) => {
          if (draggedItem && item) {
            insertBeforeItem(item);
          }
        },
        onDragStart: (
          item?: any,
          itemIndex?: number,
          selectedItems?: any[],
          event?: MouseEvent
        ) => {
          if (typeof item.startIndex === "undefined") {
            // skip group item
            draggedItem = item;
            draggedIndex = itemIndex!;
          }
        },
        onDragEnd: (item?: any, event?: DragEvent) => {
          draggedItem = undefined;
          draggedIndex = -1;
        },
        onDragEnter: (item?: any, event?: DragEvent) => {
          // return string is the css classes that will be added to the entering element.
          return dragEnterClass;
        },
      };
    };
    const dragDropEvents: IDragDropEvents = getDragDropEvents();

    return (
      <Stack>
        <CommandBar items={[
          {
            key: 'AddField',
            text: 'Add',
            iconProps: { iconName: 'Add' },
            onClick: () => {
              setTempField({ name: '', label: '', fieldType: '', lookupList: '', showInGrid: false });
              setCurrFieldIndex(undefined);
              setSidePanel('FieldEditor');
            }
          },
        ]}
        />
        <DetailsList
          columns={[{
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 100
          },
          {
            key: 'fieldType',
            name: 'Type',
            fieldName: 'fieldType',
            minWidth: 80
          },
          {
            key: 'label',
            name: 'Label',
            fieldName: 'label',
            minWidth: 100
          },
          {
            key: 'source',
            name: 'Source',
            fieldName: 'lookupList',
            minWidth: 80,
            onRender: (item: IFieldWithIndex) => (
              <Stack>
                <span>{item.fieldType === 'Lookup' ? item.lookupList :
                  item.fieldType === 'AutoComplete' ? item.reference : ''}</span>
                <Text variant="small">{item.fieldType === 'Lookup' ? '(lookup)' :
                  item.fieldType === 'AutoComplete' ? '(source)' : ''}</Text>
              </Stack>
            ),
          },
          {
            key: 'showInGrid',
            name: 'Grid',
            fieldName: 'showInGrid',
            minWidth: 35,
            onRender: (item: IFieldWithIndex) => (
              item.showInGrid ?
                <FontIcon iconName="StatusCircleCheckmark" style={{ width: 25, height: 25, fontSize: 25 }} /> : <></>
            )
          },
          {
            key: 'action',
            name: 'Action',
            minWidth: 50,
            onRender: (item: IFieldWithIndex) => (
              <Stack horizontal>
                <IconButton iconProps={{ iconName: "Edit" }} onClick={() => {
                  setSelectedLookup(undefined);
                  setCurrFieldIndex(item.index);
                  setTempField(item);
                  setSidePanel('FieldEditor');
                }} />
                <IconButton iconProps={{ iconName: "Delete" }} onClick={() => {
                  const newMetadata = {
                    lookups: metadata?.lookups ?? [],
                    fields: metadata?.fields.filter(f => f.name !== item.name) ?? [],
                    validations: metadata?.validations ?? []
                  };
                  setMetadata(newMetadata);
                  setMetadataStr(JSON.stringify(newMetadata, null, 2));
                }} />
              </Stack>
            )
          },
          ]}
          items={metadata?.fields?.map((f, index) => ({ ...f, index })) ?? []}
          selectionMode={SelectionMode.none}
          dragDropEvents={dragDropEvents}
        />
      </Stack>
    )
  }

  const ValidationsEditor = () => {
    interface IValidationWithIndex extends IValidation {
      index: number;
    }

    return (
      <Stack>
        <CommandBar items={[
          {
            key: 'AddValidation',
            text: 'Add',
            iconProps: { iconName: 'Add' },
            onClick: () => {
              setValidationAddOrEdit('add');
              setEditingValidationField(true);
              setSidePanel('ValidationEditor');
            }
          }
        ]}
        />
        <DetailsList
          columns={[
            {
              key: 'field',
              name: 'Field',
              fieldName: 'field',
              minWidth: 100
            },
            {
              key: 'action',
              name: 'Action',
              minWidth: 85,
              onRender: (item: IValidationWithIndex) => (
                <Stack horizontal>
                  <IconButton iconProps={{ iconName: "Edit" }} onClick={() => {
                    setSelectedValidation(item);
                    setValidationAddOrEdit('edit');
                    setEditingValidationField(false);
                    setSidePanel('ValidationEditor');
                  }} />
                  <IconButton iconProps={{ iconName: "Delete" }} onClick={() => {
                    const newMetadata = {
                      ...metadata,
                      lookups: metadata?.lookups ?? [], fields: metadata?.fields ?? [],
                      validations: metadata?.validations.filter(v => v.field !== item.field) ?? []
                    };
                    setMetadata(newMetadata);
                    setMetadataStr(JSON.stringify(newMetadata, null, 2));
                    setSelectedValidation(undefined);
                  }} />
                </Stack>
              )
            },
          ]}
          items={metadata?.validations?.map((v, index) => ({ ...v, index: index })) ?? []}
          selectionMode={SelectionMode.none}
        />
      </Stack>
    )
  }

  return (
    <Stack >
      <CommandBar
        items={_items}
      />
      <Stack horizontal verticalAlign='stretch'>
        <Pivot defaultSelectedKey='fields'>
          <PivotItem headerText="Fields" itemKey="fields">
            <FieldsEditor />
          </PivotItem>
          <PivotItem headerText="Lookups" itemKey="lookups">
            <LookupsEditor />
          </PivotItem>
          <PivotItem headerText="Validations" itemKey="validations">
            <ValidationsEditor />
          </PivotItem>
        </Pivot>
        <Panel
          isOpen={sidePanel !== undefined}
          onDismiss={() => setSidePanel(undefined)}
          closeButtonAriaLabel="Close"
          type={PanelType.smallFixedFar}
          headerText={sidePanel}
        >
          {sidePanel === "LookupEditor" ? <LookupEditor /> :
            sidePanel === "FieldEditor" ? <FieldEditor /> :
              sidePanel === "ValidationEditor" ? <ValidationEditor
                selectedValidation={selectedValidation}
                setSelectedValidation={(validation) => setSelectedValidation(validation)}
                setSidePanel={(panel) => setSidePanel(panel)}
                metadata={metadata}
                setMetadataStr={(metadataStr) => setMetadataStr(metadataStr ?? '')}
                validationAddOrEdit={validationAddOrEdit}
                setValidationAddOrEdit={(validationAddOrEdit) => setValidationAddOrEdit(validationAddOrEdit)}
                editingValidationField={editingValidationField}
                setEditingValidationField={(editingValidationField) => setEditingValidationField(editingValidationField)}
                setMetadata={(metadata) => setMetadata(metadata)} /> : <></>}
        </Panel>
      </Stack>
      <Modal isOpen={isCodeDialogOpen} onDismiss={hideCodeDialog}>
        <div style={{ height: 500, overflow: "auto" }}>
          <Editor
            value={metadataStr}
            onValueChange={code => setMetadataStr(code)}
            highlight={code => Prism.highlight(code, Prism.languages.js, 'js')}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
              width: '500px',
            }}
          />
        </div>
        <DialogFooter>
          <DefaultButton
            onClick={hideCodeDialog}
            text="Close" />
        </DialogFooter>
      </Modal>
      <ConfirmDialog isModalOpen={isConfirmDialogOpen} hideModal={hideConfirmDialog}
        message="All your metadata changes would be lost. Are you sure."
        onYesClick={() => {
          fetchEntityTypeData(entityType);
          setSelectedLookup(undefined);
          setSidePanel(undefined);
          hideConfirmDialog();
        }} />
    </Stack>
  )
}
