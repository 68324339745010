// import "./CreateItem.css";
import { useEffect, useRef, useState } from "react";
import {
  Dropdown,
  SearchBox,
  IDropdownOption,
  Link,
  Label,
  TextField,
  PrimaryButton,
  DefaultButton,
} from "@fluentui/react";
import { IStackStyles, Stack } from "@fluentui/react/lib/Stack";
import { getIssue, getMetadata, getZones, IIssue, IMetadata, IZone } from "../../services/assetServices";
import BackButton from "../../common/BackButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useBoolean } from "@fluentui/react-hooks";
import AssetSelector from "./AssetSelector";
import ConfirmDialog from "../../common/ConfirmDialog";
import { columnProps, labelColumnStyle, valueColumnStyle } from "../../common/styles/FormsStyles";
import OtherInfo from "../../common/OtherInfo";
import { useSetAtom } from "jotai";
import { errorMessageAtom, isInProgressAtom } from "../../atoms/messageBarAtoms";

const Issue = () => {
  const { by } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const issueId = Number.parseInt(query.get("id") ?? "");

  const navigate = useNavigate();

  const [zones, setZones] = useState<IZone[]>();
  const [selectedZone, setSelectedZone] = useState<Number>();
  const [filteredZones, setFilteredZones] = useState<IZone[]>();

  const [issue, setIssue] = useState<IIssue>();
  const savedIssue = useRef<IIssue>();
  const isSaved = useRef(false);

  const [selectedAsset, setSelectedAsset] = useState<number>();
  const [metadata, setMetadata] = useState<IMetadata>();
  const [otherInfo, setOtherInfo] = useState({});

  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const [isAssetSelectorOpen, { setTrue: showAssetSelector, setFalse: hideAssetSelector }] = useBoolean(false);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

  const fetchData = async () => {
    const abortController = new AbortController();
    setIsInProgress(true);
    try {
      const data: IZone[] = await getZones(abortController);
      setZones(data);
      setFilteredZones(data);

      if (issueId) {
        const data: IIssue = await getIssue(abortController, issueId);
        setIssue(data);
        setOtherInfo(JSON.parse(data.otherInfo ?? "{}"));
        savedIssue.current = { ...data };

        const metadata = await getMetadata(abortController, 'Issue');
        setMetadata(metadata);
      }
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    }
    finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const stackTokens = { childrenGap: 50 };
  const stackStyles: Partial<IStackStyles> = { root: { width: 450 } };

  const handleChange = (fieldName: string, value?: string) => {
    setIssue((prev: any) => {
      const newAsset = { ...prev };
      newAsset[fieldName] = value;
      return newAsset;
    });
  };

  return (
    <div>
      <BackButton backTo="/issues" />
      {by === "zone" ?
        <Stack horizontal style={{ width: '450px' }}>
          <SearchBox
            placeholder="Search zone"
            onSearch={(newValue) => setFilteredZones(zones?.filter(zone => zone.name?.includes(newValue)))}
            onClear={() => setFilteredZones(zones)}
            disableAnimation
            styles={{ root: { width: '180px' } }}
          />
          <Dropdown
            options={filteredZones ? filteredZones?.map(z => ({ key: z.id ?? 0, text: z.name ?? "" })) : []}
            onChange={(ev, selectedOption?: IDropdownOption) => {
              setSelectedZone(Number.parseInt((selectedOption?.key ?? "").toString()))
            }}
            styles={{ root: { width: '180px' } }}
          />
        </Stack> :
        by === "asset" ?
          <Stack>
            <Link onClick={() => showAssetSelector()} >Select Asset</Link>
            <AssetSelector
              isOpen={isAssetSelectorOpen}
              hideAssetSelector={hideAssetSelector}
              onAssetLinkClick={(assetId) => {
                setSelectedAsset(assetId);
                hideAssetSelector();
              }}
            />
          </Stack> :
          <Stack>
            <form>
              <div>
                <div className="form-group">
                  <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                    <Stack {...columnProps}>
                      <Stack horizontal>
                        <Label style={labelColumnStyle}>Id: </Label>
                        <TextField style={valueColumnStyle} value={issue?.id.toString()}
                          readOnly={true} disabled={true}
                        />
                      </Stack>
                      <Stack horizontal>
                        <Label style={labelColumnStyle}>UUID: </Label>
                        <TextField style={valueColumnStyle} value={issue?.uuid.toString()}
                          readOnly={true} disabled={true}
                        />
                      </Stack>
                      <Stack horizontal>
                        <Label style={labelColumnStyle}>Comment: </Label>
                        <TextField style={valueColumnStyle} value={issue?.comment}
                          onChange={(_, newValue) => handleChange('name', newValue)}
                          readOnly={isSaved.current}
                        />
                      </Stack>
                      <Stack horizontal>
                        <Label style={labelColumnStyle}>Asset: </Label>
                        <TextField style={valueColumnStyle} value={(issue?.assetId ?? "").toString()}
                          onChange={(_, newValue) => handleChange('name', newValue)}
                          readOnly={isSaved.current}
                        />
                      </Stack>
                      <Dropdown
                        label="Zone"
                        options={zones?.map(zone => ({ key: zone.id ?? '', text: zone.name ?? '' })) ?? []}
                        selectedKey={issue?.zoneId}
                        style={{ width: 380 }}
                        disabled={isSaved.current}
                        onRenderTitle={(options) => {
                          if (!options) {
                            return <></>
                          }
                          const option = options[0];
                          return (
                            options ? <span>{option.text.substring(option.text.indexOf('/') + 1)}</span> : <span></span>
                          )
                        }}
                        onRenderOption={(option, context) => (
                          (option && option.text) ?
                            <Stack horizontal>
                              <span
                                style={{ width: option.text.indexOf('/') * 14 }}>
                              </span>
                              <span>{option.text.substring(option.text.indexOf('/') + 1)}</span>
                            </Stack> : <div></div>
                        )}
                        onChange={(e, o) => {
                          setIssue((prevItem: any) => ({ ...prevItem, zoneId: o?.key }));
                        }}
                      />
                      {/* <Dropdown
                        label="Status"
                        options={assetStatuses?.map(status => ({ key: status.id ?? '', text: status.name ?? '', data: status })) ?? []}
                        selectedKey={asset?.statusId}
                        disabled={isSaved.current}
                        onRenderTitle={(options) => {
                          if (!options) {
                            return <></>
                          }
                          const option = options[0];
                          return (
                            options ? <span>{option.text}</span> : <span></span>
                          )
                        }}
                        onRenderOption={(option, context) => (
                          (option && option.text) ?
                            <Stack horizontal>
                              <FontIcon iconName={option.data.icon} className={statusIconClass} />
                              <span>{option.text}</span>
                            </Stack> : <div></div>
                        )}
                        onChange={(e, o) => {
                          setAsset((prevItem: any) => ({ ...prevItem, statusId: o?.key }));
                        }}
                      /> */}
                      {metadata ?
                        <OtherInfo metadata={metadata} otherInfo={JSON.stringify(otherInfo)} isSaved={isSaved}
                          onOtherInfoChanged={(newValue: string) => setOtherInfo(newValue)} /> :
                        <></>}
                      <Stack.Item align="center">
                        <Stack horizontal tokens={stackTokens}>
                          <PrimaryButton type="submit">Save</PrimaryButton>
                          <DefaultButton
                            onClick={() => {
                              if (savedIssue.current?.assetId !== issue?.assetId ||
                                savedIssue.current?.comment !== issue?.comment ||
                                savedIssue.current?.zoneId !== issue?.zoneId) {
                                showModal();
                              } else {
                                navigate("/");
                              }
                            }}
                          >
                            Cancel
                          </DefaultButton>
                        </Stack>
                      </Stack.Item>
                    </Stack>
                  </Stack>

                  <ConfirmDialog isModalOpen={isModalOpen} hideModal={hideModal}
                    message="All your unsaved changes would be lost."
                    onYesClick={() => navigate("/issues")} />

                </div>
              </div>
            </form>
          </Stack>
      }
      {selectedZone ?
        <iframe
          title="Create issue"
          src={`${process.env.REACT_APP_CLIENT_URL}/?zone=${selectedZone}&emulationMode=true`}
          style={{ width: '450px', height: '800px' }}
        /> :
        selectedAsset ?
          <iframe
            title="Create issue"
            src={`${process.env.REACT_APP_CLIENT_URL}/${selectedAsset}&emulationMode=true`}
            style={{ width: '450px', height: '800px' }}
          /> :
          <></>}
    </div>
  );
};

export default Issue;
