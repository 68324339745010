import "./Contractors.css";
import {
  CommandBar,
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  IconButton,
  ITextField,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from "@fluentui/react";
import { Selection } from "@fluentui/react/lib/DetailsList";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appRoles } from "../../authConfig";
import { scrollStackItemStyles } from "../../common/styles/StackStyles";
import { getContractors, getImage, IImage, getMetadata, getWorkOrderStatuses, IContractor, IPagedCollection, IWorkOrderStatuses } from "../../services/assetServices";
import AppContext from "../AppContext";
import { StackedBarChart } from "@fluentui/react-charting";
import { useAtomValue, useSetAtom } from "jotai";
import { errorMessageAtom, isInProgressAtom } from "../../atoms/messageBarAtoms";
import { profileDataAtom } from "../../atoms/authAtoms";

function Contractors() {
  const [contractors, setContractors] = useState<IContractor[]>([]);
  const [workOrdersStatuses, setWorkOrdersStatuses] = useState<IWorkOrderStatuses[]>([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState({ no: 1, search: "" });
  const [shimmered, setShimmered] = useState(false);
  const _columns: IColumn[] = [
    {
      key: "Id",
      name: "Id",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 50,
      isResizable: true,
    },
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: "code",
      name: "Code",
      fieldName: "code",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "workOrders",
      name: "WorkOrders",
      fieldName: "workOrders",
      minWidth: 80,
      maxWidth: 120,
      isResizable: true,
      className: 'chartRowCell',
    },
    {
      key: "createdAt",
      name: "Created at",
      fieldName: "createdAt",
      minWidth: 125,
      maxWidth: 155,
      isResizable: true,
    },
  ];
  const columns = useRef<IColumn[]>(_columns);

  const [selectedContractor, setSelectedContractor] = useState<IContractor>();

  const searchTextField = useRef<ITextField>(null);

  const navigate = useNavigate();

  const context = useContext(AppContext);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom); const profileData = useAtomValue(profileDataAtom);

  const _images: Map<string, string> = new Map<string, string>();

  const fetchData = async () => {
    context.setSelectedTab("Contractors");
    setIsInProgress(true);
    const abortController = new AbortController();

    try {
      const workOrderStatuses: IWorkOrderStatuses[] = await getWorkOrderStatuses(abortController);
      setWorkOrdersStatuses(workOrderStatuses);
      const metadata = await getMetadata(abortController, 'Contractor');
      const otherInfoFields = metadata?.fields.filter(mf => mf.showInGrid);

      const imagePaths: string[] = [];

      const data: IPagedCollection<IContractor> = await getContractors(abortController, 10, page.no, page.search);
      const _contractors = (data?.items?.map(contractor => {
        const newContractor: any = {
          ...contractor,
          createdAt: new Date(contractor.createdAt + 'Z').toLocaleString()
        };
        const otherInfoParts = contractor.otherInfo.split('|');
        otherInfoFields?.forEach((mf, index) => {
          const part = otherInfoParts[index];
          newContractor[mf.name] = part;
          if (mf.fieldType === 'Image') {
            imagePaths.push(part.replace('attachments', 'thumbnails'));
          }
        });
        return newContractor;
      }));
      await Promise.all(imagePaths.filter(image => image !== "")?.map(async (image) => {
        if (!_images.has(image)) {
          await getImage(abortController, image, "thumbnails").then((data: IImage) => {
            const imageBase64 = data.imageContent;
            _images.set(image, `${imageBase64}`);
          })
        }
      }));
      otherInfoFields?.forEach(mf => {
        if (columns.current.find(c => c.key === mf.name)) {
          columns.current.push({
            key: mf.name,
            name: mf.name,
            fieldName: mf.name,
            minWidth: 100,
            onRender: mf.fieldType !== 'Image' ? undefined : (item: any) => (
              <img src={_images.get(item[mf.name].replace('attachments', 'thumbnails'))} style={{ width: 20 }} alt="" />
            )
          })
        }
      });
      setContractors(_contractors);
      setIsLastPage(data?.isLastPage);
      setPageCount(data?.pageCount);
      setShimmered(false);
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    }
  }

  useEffect(() => {
    fetchData();
  }, [page.no, page.search, columns]);

  const CustomTooltip = (param: any) => {
    if (param.active && param.payload && param.payload.length) {
      const label = param.payload.map((p: any) => `${p.name}:${p.value}`).join(',');
      return (
        <div className="custom-tooltip" >
          <p className="label">{label}</p>
        </div>
      );
    }

    return null;
  };

  const _renderItemColumn = (
    item: IContractor,
    index?: number,
    column?: IColumn
  ) => {
    const fieldContent = item[column?.fieldName as keyof IContractor] as string;

    switch (column?.key) {
      case "workOrders":
        const fills = new Map<string, string>();
        for (const wos of (workOrdersStatuses ?? [])) {
          fills.set(wos.code, wos.color);
        }

        return item.workOrders ? (
          <StackedBarChart
            data={{
              chartTitle: '',
              chartData: Object.keys(item.workOrders)?.map((key: string) => (
                { legend: key, data: Number.parseInt(item.workOrders[key]), color: fills.get(key) }
              )),
            }}
            hideNumberDisplay={true}
            hideLegend={true} />
        ) : (
          <span></span>
        );
      default:
        return <span>{fieldContent}</span>;
    }
  };
  const _items: ICommandBarItemProps[] = [
    {
      key: "newContractor",
      text: "New",
      iconProps: { iconName: "Add" },
      disabled: !profileData.roles.includes(appRoles.Admin),
      onClick: () => {
        navigate('/newContractor');
      },
    },
    {
      key: "editContractor",
      text: "Edit",
      iconProps: { iconName: "Edit" },
      disabled: !profileData.roles.includes(appRoles.Admin) ||
        !selectedContractor,
      onClick: () => {
        navigate(`/editContractor?id=${selectedContractor?.id}`);
      },
    },
  ];

  const _selection = new Selection({
    onSelectionChanged: () => {
      setSelectedContractor(_selection.getSelection()[0] as IContractor);
    },
  });

  return (
    <>
      <Stack>
        <CommandBar
          items={_items}
          ariaLabel="Items actions"
          primaryGroupAriaLabel="Items actions"
          farItemsGroupAriaLabel="More actions"
        />
        <Stack>
          <Stack.Item align="start" styles={scrollStackItemStyles}>
            {contractors !== undefined ?
              <ShimmeredDetailsList
                items={contractors}
                columns={columns.current}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                onRenderItemColumn={_renderItemColumn}
                selectionMode={SelectionMode.single}
                selection={_selection}
                selectionPreservedOnEmptyClick={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                enableShimmer={shimmered}
              /> : <></>}
          </Stack.Item>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Stack.Item grow={1} align="center">
            <IconButton
              iconProps={{ iconName: "DoubleChevronLeft" }}
              disabled={page.no === 1}
              onClick={() => setPage({ no: 1, search: page.search })}
            />
            <IconButton
              iconProps={{ iconName: "ChevronLeft" }}
              disabled={page.no === 1}
              onClick={() => setPage({ no: page.no - 1, search: page.search })}
            />
            <Text>
              {page.no} of {pageCount}
            </Text>
            <IconButton
              iconProps={{ iconName: "ChevronRight" }}
              disabled={isLastPage}
              onClick={() => setPage({ no: page.no + 1, search: page.search })}
            />
            <IconButton
              iconProps={{ iconName: "DoubleChevronRight" }}
              disabled={isLastPage}
              onClick={() => setPage({ no: pageCount, search: page.search })}
            />
          </Stack.Item>
        </Stack>
      </Stack>
    </>
  );
}

export default Contractors;
