import { DefaultButton, IStackTokens, List, PrimaryButton, Stack, Text } from '@fluentui/react';
import React, { useRef, useState } from 'react'
import { IAssetCategories2IssueTypesResponse, uploadAssetCategories2IssueTypes } from '../../services/assetServices';

const ImportIssueTypes = () => {

  const [selectedFile, setSelectedFile] = useState<File>();
  const [isSelected, setIsSelected] = useState(false);
  const [result, setResult] = useState<IAssetCategories2IssueTypesResponse>();

  const fileUploadRef = useRef(null);

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const handleSubmission = () => {
    const blob: Blob = selectedFile as Blob;
    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      const text = reader.result;
      const abortController = new AbortController();

      uploadAssetCategories2IssueTypes(
        abortController,
        {
          csvContent: text as string
        },
      ).then((response: IAssetCategories2IssueTypesResponse) => {
        setResult(response);
      })
    })
    reader.readAsText(blob);

  };

  const stackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10,
  };

  return (
    <Stack horizontalAlign='start' tokens={stackTokens}>
      <Text variant='large'>Import Categories to IssueTypes Matrix</Text>
      <DefaultButton onClick={() => {
        (fileUploadRef?.current as unknown as HTMLInputElement).click()
      }}>Choose file</DefaultButton>
      <input type="file" name="file"
        ref={fileUploadRef} onChange={changeHandler} style={{ display: "none" }} />
      {isSelected ? (
        <Stack horizontalAlign='start' >
          <p>Filetype: {selectedFile?.type}</p>
          <p>Size in bytes: {selectedFile?.size}</p>
          <p>
            lastModifiedDate:{' '}
            {new Date(selectedFile?.lastModified ?? 0).toLocaleDateString()}
          </p>
        </Stack>
      ) : (
        <p>Select a file to show details</p>
      )}
      <div>
        <PrimaryButton onClick={handleSubmission} disabled={!isSelected}>Submit</PrimaryButton>
      </div>
      {result ?
        <>
          <Text variant='large'>{result?.isSuccess ? "Success" : "Failed"}</Text>
          <List items={result?.errors}
          ></List>
        </> : <></>}
    </Stack>
  )
}

export default ImportIssueTypes