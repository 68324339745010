import {
  INavLink, INavLinkGroup, Text, Nav, Stack, TextField,
  Label, CommandBar, ICommandBarItemProps,
  PrimaryButton, DefaultButton, NavBase, Panel, DetailsList, SelectionMode, PanelType, IconButton, ITextField
} from '@fluentui/react';
import { useBoolean } from "@fluentui/react-hooks";
import { useContext } from 'react';
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { appRoles } from '../authConfig';
import ConfirmDialog from '../common/ConfirmDialog';
import { createCategory, editCategory, getAssetCategories, ICreateCategoryRequest, ICreateCategoryResponse, IUpdateCategoryRequest, IAssetCategory, IIsueType, getAssetCategoryIssueTypes } from '../services/assetServices';
import AppContext from './AppContext';
import PanelSplitter from '../common/PanelSplitter';
import { useAtomValue, useSetAtom } from 'jotai';
import { clearMessageAtom, errorMessageAtom, isInProgressAtom, successMessageAtom } from "../atoms/messageBarAtoms";
import { profileDataAtom } from "../atoms/authAtoms";

const Categories = () => {
  const query = new URLSearchParams(useLocation().search);
  const catId = Number.parseInt(query.get("id") ?? "");

  const context = useContext(AppContext);
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const clearMessage = useSetAtom(clearMessageAtom);
  const profileData = useAtomValue(profileDataAtom);

  const [categories, setCategories] = useState<INavLinkGroup[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<IAssetCategory>();
  const [inEditMode, setInEditMode] = useState(false);

  const newIssueTypeNameRef = useRef<ITextField>(null);
  const newIssueTypeCodeRef = useRef<ITextField>(null);

  const savedCategory = useRef<IAssetCategory>();

  const loadCategories = (catId?: number) => {
    context.setSelectedTab("Categories");
    const abortController = new AbortController();

    getAssetCategories(abortController)
      .then((categories: IAssetCategory[]) => {
        const links: INavLink[] = [];
        const groups: INavLinkGroup[] = [{ name: 'Categories', links: links }];
        const catMap = new Map<number, IAssetCategory>();
        addChildCategories(links, categories, catMap);
        setCats(catMap);
        setCategories(groups);
        if (catId) {
          handleSelectionChange(catId);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage(`Error: ${error}`);
      });
    setInEditMode(false);
    return () => {
      abortController.abort();
    }
  };

  const [assetCatIssueTypes, setAssetCatIssueTypes] = useState(new Map<number, IIsueType[]>());
  const [cats, setCats] = useState(new Map<number, IAssetCategory>());
  useEffect(() => {
    loadCategories(catId);
  }, [])

  const handleChange = (e: any) => {
    switch (e.target.name) {
      case 'code':
        setSelectedCategory((prevItem: any) => ({ ...prevItem, code: e.target.value }));
        break;
      case 'name':
        setSelectedCategory((prevItem: any) => ({ ...prevItem, name: e.target.value }));
        break;
      default:
        break;
    }
  };

  const handleSave = () => {
    setIsInProgress(true);
    const abortController = new AbortController();

    if (selectedCategory?.id) {
      const updateCategoryRequest: IUpdateCategoryRequest = {
        code: selectedCategory?.code,
        name: selectedCategory?.name,
        parentId: Number(selectedCategory?.parentId),
        issueTypes: selectedCategory?.issueTypes,
      };

      editCategory(abortController, selectedCategory?.id?.toString() ?? '', updateCategoryRequest)
        .then((data: number) => {
          setSuccessMessage("Category successfully updated");
          setSelectedCategory({
            id: data,
            code: updateCategoryRequest.code ?? '',
            name: updateCategoryRequest.name ?? '',
            parentId: updateCategoryRequest.parentId ?? 0,
            issueTypes: updateCategoryRequest.issueTypes ?? [],
          });
          loadCategories();
        })
        .catch((error) => {
          console.error("Error:", error);
          setErrorMessage(`Error: ${error}`);
        })
        .finally(() => setIsInProgress(false));
    } else {
      const createCategoryRequest: ICreateCategoryRequest = {
        name: selectedCategory?.name ?? '',
        code: selectedCategory?.code ?? '',
        parentId: selectedCategory?.parentId,
        issueTypes: selectedCategory?.issueTypes,
      };
      createCategory(abortController, createCategoryRequest)
        .then((data: ICreateCategoryResponse) => {
          setSuccessMessage(`Category successfully created ${data.assetCategoryId}`);
          loadCategories();
        })
        .catch((error) => {
          console.error("Error:", error);
          setErrorMessage(`Error: ${error}`);
        })
        .finally(() => setIsInProgress(false));
    }
  }

  const handleSelectionChange = (selectedCatId: number) => {
    const issueTypes = assetCatIssueTypes.get(selectedCatId);
    if (!cats.has(selectedCatId)) {
      return;
    }
    const cat: IAssetCategory = cats.get(selectedCatId) ?? { code: '', name: '' };
    if (!issueTypes) {
      const abortController = new AbortController();
      getAssetCategoryIssueTypes(abortController, selectedCatId.toString())
        .then((issueTypes: IIsueType[]) => {
          setSelectedCategory({ ...cat, issueTypes: issueTypes });
          setAssetCatIssueTypes(new Map(assetCatIssueTypes?.set(cat.id ?? 0, issueTypes)));
        })
    } else {
      setSelectedCategory({ ...cat, issueTypes: issueTypes });
    }
  }

  const _items: ICommandBarItemProps[] = [
    {
      key: "newCategory",
      text: "New",
      iconProps: { iconName: "Add" },
      disabled: !profileData.roles.includes(appRoles.Admin),
      onClick: () => {
        setSelectedCategory((prevItem: any) => ({
          ...prevItem,
          name: '', code: '', id: 0, parentId: selectedCategory?.id
        }));

        savedCategory.current = { code: '', name: '' };
        setInEditMode(true);
        clearMessage();
      },
    },
    {
      key: "categoryEdit",
      text: "Edit",
      iconProps: { iconName: "Edit" },
      onClick: () => {
        setInEditMode(true);
        clearMessage();
        savedCategory.current = { code: selectedCategory?.code || '', name: selectedCategory?.name || '' };
      },
      disabled: !profileData.roles.includes(appRoles.Admin) || !selectedCategory,
    },
    {
      key: "categoryDelete",
      text: "Delete",
      iconProps: { iconName: "Delete" },
      onClick: () => {
      },
      disabled: !profileData.roles.includes(appRoles.Admin) || !selectedCategory,
    },
  ];

  const stackTokens = { childrenGap: 50 };

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const [isNewIssueTypeOpen, { setTrue: showNewIssueType, setFalse: hideNewIssueType }] =
    useBoolean(false);

  const navRef = useRef<NavBase>(null);

  return (
    <Stack>
      <Stack horizontal>
        <PanelSplitter firstPanelSize={250} secondPanelSize={450}>
          <Nav groups={categories}
            componentRef={navRef}
            onLinkClick={(e, item) => {
              if (!item?.key) {
                return;
              }
              const catId = Number.parseInt(item.key);
              handleSelectionChange(catId);
            }} />
          <Stack>
            <CommandBar
              items={_items}
              ariaLabel="Items actions"
              primaryGroupAriaLabel="Items actions"
              farItemsGroupAriaLabel="More actions"
            />
            <Stack tokens={{ childrenGap: 5, padding: 14 }} style={{ display: selectedCategory ? '' : 'none' }}>
              <Text variant="large" >Properties</Text>
              <Stack horizontal>
                <Label style={{ width: '75px' }}>Name: </Label>
                <TextField value={selectedCategory?.name} readOnly={!inEditMode}
                  name="name"
                  borderless={!inEditMode}
                  onChange={handleChange} />
              </Stack>
              <Stack horizontal>
                <Label style={{ width: '75px' }}>Code: </Label>
                <TextField value={selectedCategory?.code} readOnly={!inEditMode}
                  name="code"
                  borderless={!inEditMode}
                  onChange={handleChange} />
              </Stack>
              <Stack>
                <Text variant='large'>Issue types</Text>
                <CommandBar
                  items={[{
                    key: 'newIssueType',
                    text: 'New issue type',
                    iconProps: { iconName: "DashboardAdd" },
                    disabled: !profileData.roles.includes(appRoles.Admin) || !inEditMode,
                    onClick: () => showNewIssueType(),
                  }]} />
                <DetailsList styles={{ root: { width: 445 } }}
                  columns={[
                    {
                      key: "Code",
                      name: "Code",
                      fieldName: "code",
                      minWidth: 100,
                      maxWidth: 125,
                      isResizable: true,
                    },
                    {
                      key: "Name",
                      name: "Name",
                      fieldName: "name",
                      minWidth: 225,
                      maxWidth: 250,
                      isResizable: true,
                    },
                    {
                      key: 'remove',
                      name: '',
                      minWidth: 50,
                      maxWidth: 50,
                      isResizable: true,
                      onRender: (item: any) => (
                        <IconButton iconProps={{ iconName: 'Delete' }} disabled={!inEditMode}
                          onClick={() => {
                            if (selectedCategory) {
                              setSelectedCategory({ ...selectedCategory, issueTypes: selectedCategory?.issueTypes?.filter(it => it.id !== item.id) });
                            }
                          }}></IconButton>
                      )
                    },
                  ]}
                  compact={true}
                  items={selectedCategory?.issueTypes ?? []}
                  selectionMode={SelectionMode.none}
                />
              </Stack>
              <Stack>
                <Stack.Item align="center" style={{ display: inEditMode ? '' : 'none' }}>
                  <Stack horizontal tokens={stackTokens}>
                    <PrimaryButton onClick={() => { handleSave() }}>
                      Save
                    </PrimaryButton>
                    <DefaultButton onClick={() => {
                      if (savedCategory.current?.code !== selectedCategory?.code ||
                        savedCategory.current?.name !== selectedCategory?.name) {
                        showModal();
                      } else {
                        setInEditMode(false);
                        clearMessage();
                      }
                    }}>Cancel</DefaultButton>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack>
          </Stack>
        </PanelSplitter>
      </Stack>
      <ConfirmDialog isModalOpen={isModalOpen} hideModal={hideModal}
        message="All your unsaved changes would be lost."
        onYesClick={() => {
          hideModal();
          setSelectedCategory(savedCategory.current);
          setInEditMode(false);
        }} />
      <Panel
        isLightDismiss
        isOpen={isNewIssueTypeOpen}
        onDismiss={hideNewIssueType}
        closeButtonAriaLabel="Close"
        type={PanelType.smallFixedFar}
        headerText="Define a new issue type"
      >
        <Stack horizontal>
          <Label style={{ width: '75px' }}>Name: </Label>
          <TextField name="name" componentRef={newIssueTypeNameRef} />
        </Stack>
        <Stack horizontal>
          <Label style={{ width: '75px' }}>Code: </Label>
          <TextField name="code" componentRef={newIssueTypeCodeRef} />
        </Stack>
        <PrimaryButton onClick={
          () => {
            if (selectedCategory && selectedCategory?.issueTypes) {
              setSelectedCategory({
                ...selectedCategory,
                issueTypes: [...selectedCategory.issueTypes,
                { code: newIssueTypeCodeRef.current?.value ?? '', name: newIssueTypeNameRef.current?.value ?? '' }
                ]
              });
            }
            hideNewIssueType();
          }
        }>Save</PrimaryButton>
      </Panel>
    </Stack>
  )
}

export default Categories

function addChildCategories(links: INavLink[], subCategories: IAssetCategory[], catMap: Map<number, IAssetCategory>) {
  for (const childCategory of subCategories) {
    const navLink: INavLink = {
      name: childCategory.name, url: '',
      key: childCategory.id?.toString(),
    };
    if (childCategory.id) {
      catMap.set(childCategory.id ?? 0, childCategory);
    }
    if (childCategory.subCategories) {
      const subLinks: INavLink[] = [];
      addChildCategories(subLinks, childCategory.subCategories, catMap);
      navLink.links = subLinks;
    }
    links.push(navLink);
  }
}
