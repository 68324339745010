import { appRoles } from '../../authConfig';
import PagedList from '../../common/PagedList'
import { getUsers } from '../../services/assetServices'
import { useAtomValue } from 'jotai';
import { profileDataAtom } from '../../atoms/authAtoms';


const Users = () => {
  const profileData = useAtomValue(profileDataAtom);

  return (
    <PagedList selectedTab='Users'
      columns={[
        { key: 'id' },
        { key: 'userName' },
        { key: 'firstName' },
        { key: 'lastName' },
        { key: 'email', minWidth: 200 },
      ]}
      getAction={getUsers}
      commandBarItems={[
        {
          key: "newUser",
          text: "New",
          iconProps: { iconName: "Add" },
          // onClick: () => navigate("/newUser"),
          disabled: !profileData.roles.includes(appRoles.Admin)
        },
        {
          key: "editUser",
          text: "Edit",
          iconProps: { iconName: "Edit" },
          // onClick: () => navigate("/editUser"),
          disabled: !profileData.roles.includes(appRoles.Admin)
        },
      ]}
    ></PagedList>
  )
}

export default Users