import {
  INavLink, INavLinkGroup, Text, Nav, Stack, TextField,
  Label, CommandBar, ICommandBarItemProps,
  PrimaryButton, DefaultButton, Checkbox, mergeStyleSets, IRenderGroupHeaderProps, SearchBox, IconButton, ISearchBox, FontIcon
} from '@fluentui/react';
import { useBoolean } from "@fluentui/react-hooks";
import _ from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { appRoles } from '../../authConfig';
import ConfirmDialog from '../../common/ConfirmDialog';
import { flattenCategoriesTree } from '../../common/FlattenHelper';
import {
  createZone, deleteZone, editZone, getAssetCategories, getZones, IAssetCategory, ICreateZoneRequest, ICreateZoneResponse, IUpdateZoneRequest, IZone
} from '../../services/assetServices';
import AppContext from '../AppContext';
import PanelSplitter from '../../common/PanelSplitter';
import { useAtomValue, useSetAtom } from 'jotai';
import { errorMessageAtom, isInProgressAtom, successMessageAtom } from '../../atoms/messageBarAtoms';
import { profileDataAtom } from '../../atoms/authAtoms';

const Zones = () => {
  const [zones, setZones] = useState<IZone[]>();
  const [navLinkGroups, setNavLinkGroups] = useState<INavLinkGroup[]>([]);
  const [selectedZone, setSelectedZone] = useState<IZone>();
  const [inEditMode, setInEditMode] = useState(false);
  const [inDeleteMode, setInDeleteMode] = useState(false);

  const [confirmDialogMessage, setConfirmDialogMessage] = useState("");
  const savedZone = useRef<IZone>();

  const context = useContext(AppContext);
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const profileData = useAtomValue(profileDataAtom);
  const navigate = useNavigate();

  const [categories, setCategories] = useState<IAssetCategory[]>([]);

  const setZonesInNavLinkGroups = (zones: IZone[], isExpanded: boolean) => {
    const links: INavLink[] = [];
    const groups: INavLinkGroup[] = [{ name: 'Zones', links: links }];
    addChildZones(links, zones, isExpanded);
    setNavLinkGroups(groups);
  }

  const loadZones = () => {
    context.setSelectedTab("Zones");
    const abortController = new AbortController();

    setIsInProgress(true);
    getAssetCategories(abortController)
      .then((data: IAssetCategory[]) => {
        const flattenList: IAssetCategory[] = [];
        flattenCategoriesTree(data, flattenList, '');
        setCategories(flattenList);
        getZones(abortController, '')
          .then((zones: IZone[]) => {
            setZones(zones);
            setZonesInNavLinkGroups(zones, false);
          })
          .catch((error) => {
            console.error("Error:", error);
            setErrorMessage(`Error: ${error}`);
          });
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage(error.message);
      })
      .finally(() => {
        setIsInProgress(false);
      });

    setInEditMode(false);
    return () => {
      abortController.abort();
    }
  };
  useEffect(() => loadZones(), [])

  const handleChange = (e: any) => {
    switch (e.target.name) {
      case 'code':
        setSelectedZone((prevItem: any) => ({ ...prevItem, code: e.target.value }));
        break;
      case 'name':
        setSelectedZone((prevItem: any) => ({ ...prevItem, name: e.target.value }));
        break;
      default:
        break;
    }
  };

  const handleCategoriesChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    if (!inEditMode) {
      return;
    }

    const catId: number = ((ev?.target as any).id);
    let newAssetCategories: IAssetCategory[] = _.cloneDeep(selectedZone?.assetCategories) ?? [];
    if (checked) {
      // eslint-disable-next-line eqeqeq
      const cat = categories.find(cat => cat.id == catId);
      if (cat) {
        newAssetCategories.push(cat);
      }
    } else {
      // eslint-disable-next-line eqeqeq
      _.remove(newAssetCategories, aCat => aCat.id == catId);
    }
    setSelectedZone({ ...selectedZone, name: selectedZone?.name, assetCategories: newAssetCategories });
  }

  const handleSave = () => {
    setIsInProgress(true);
    const abortController = new AbortController();

    if (selectedZone?.id) {
      const updateZoneRequest: IUpdateZoneRequest = {
        code: selectedZone?.code,
        name: selectedZone?.name,
        parentId: undefined, //Number(selectedZone?.categoryId),
        assetCategories: selectedZone?.assetCategories?.filter(ac => ac.id)?.map(ac => ac.id ?? 0) ?? [],
      };

      editZone(abortController, selectedZone?.id?.toString() ?? '', updateZoneRequest)
        .then((data: number) => {
          setSuccessMessage("Zone successfully updated");
          setSelectedZone({
            id: data,
            code: updateZoneRequest.code ?? '',
            name: updateZoneRequest.name ?? '',
            // parentId: updateZoneRequest.parentId ?? 0
            assetCategories: selectedZone?.assetCategories
          });
          loadZones();
        })
        .catch((error) => {
          console.error("Error:", error);
          setErrorMessage(`Error: ${error}`);
        })
        .finally(() => setIsInProgress(false));
    } else {
      const createZoneRequest: ICreateZoneRequest = {
        name: selectedZone?.name ?? '',
        code: selectedZone?.code ?? '',
        parentId: selectedZone?.parentId,
        assetCategories: selectedZone?.assetCategories?.map(aCat => aCat.id || 0).filter(id => id > 0),
      };
      createZone(abortController, createZoneRequest)
        .then((data: ICreateZoneResponse) => {
          setSuccessMessage(`Zone successfully created ${data.zoneId}`);
          loadZones();
        })
        .catch((error: any) => {
          console.error("Error:", error);
          setErrorMessage(`Error: ${error}`);
        })
        .finally(() => setIsInProgress(false));
    }
  }

  const handleDelete = () => {
    if (selectedZone?.id) {
      setIsInProgress(true);
      const abortController = new AbortController();
      deleteZone(abortController, selectedZone?.id?.toString())
        .then((data: number) => {
          hideModal();
          setSuccessMessage(`Zone ${data} deleted`);
          loadZones();
        })
        .catch((error) => {
          console.error("Error:", error);
          setErrorMessage(`Error: ${error}`);
        })
        .finally(() => {
          setInDeleteMode(false);
          setIsInProgress(false);
        });
    }
  }

  const _items: ICommandBarItemProps[] = [
    {
      key: "showQR",
      text: "Show QR",
      iconProps: { iconName: "QRCode" },
      onClick: () => {
        navigate(`/showQR?zoneId=${selectedZone?.id}`);
      },
      disabled: !selectedZone,
    },
    {
      key: "newZone",
      text: "New",
      iconProps: { iconName: "Add" },
      disabled: !profileData.roles.includes(appRoles.Admin),
      onClick: () => {
        setSelectedZone((prevItem: any) => ({
          ...prevItem,
          name: '', code: '', id: 0, parentId: selectedZone?.id, assetCategories: []
        }));
        savedZone.current = { code: '', name: '' };
        setInEditMode(true);
      },
    },
    {
      key: "zoneEdit",
      text: "Edit",
      iconProps: { iconName: "Edit" },
      onClick: () => {
        setInEditMode(true);
        savedZone.current = { code: selectedZone?.code || '', name: selectedZone?.name || '' };
      },
      disabled: !profileData.roles.includes(appRoles.Admin) || !selectedZone,
    },
    {
      key: "zoneDelete",
      text: "Delete",
      iconProps: { iconName: "Delete" },
      onClick: () => {
        setInDeleteMode(true);
        setConfirmDialogMessage("The Zone would be deleted");
        showModal();
      },
      disabled: !profileData.roles.includes(appRoles.Admin) || !selectedZone,
    },
  ];

  const stackTokens = { childrenGap: 50 };

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);


  const classNames = mergeStyleSets({
    CheckboxListContainer: {
      height: 500,
      overflow: 'auto',
    },
    ZonesListContainer: {
      height: 500,
      overflow: 'auto',
    },
    CheckboxItem: {
      height: 24,
    },
    CheckboxSpansInNonEditMode: {
      marginLeft: 4,
    },
    CheckboxIconInNonEditMode: {
      fontSize: 20,
      marginRight: 4,
    }
  });
  const [showSearchBox, setShowSearchBox] = useState(false);
  const searchboxRef = useRef<ISearchBox>(null);
  const renderGroupHeader = (props?: IRenderGroupHeaderProps, defaultRender?: (props?: IRenderGroupHeaderProps) => JSX.Element | null):
    JSX.Element | null => (
    defaultRender ?
      <Stack horizontal>{defaultRender(props)}
        <div style={{ display: showSearchBox ? '' : 'none', width: '75px' }}>
          <SearchBox componentRef={searchboxRef}
            onChange={(ev, newValue) => {
              if (newValue === undefined) {
                return;
              }
              const newZones = filterChildZones(zones ?? [],
                z => (z.name !== undefined && z.name.toUpperCase().indexOf(newValue.toUpperCase()) > -1));

              setZonesInNavLinkGroups(newZones, true);
            }}
            onClear={() => {
              setZonesInNavLinkGroups(zones ?? [], false);
              setShowSearchBox(false);
            }}
          />
        </div>
        <IconButton iconProps={{ iconName: 'Search' }} style={{ display: showSearchBox ? 'none' : '' }} onClick={() => {
          setShowSearchBox(true);
          searchboxRef.current?.focus();
        }} />
      </Stack> : <></>
  )
  return (
    <Stack>
      <Stack horizontal>
        <PanelSplitter firstPanelSize={150} secondPanelSize={350}>
          <div className={classNames.ZonesListContainer} >
            <Nav groups={navLinkGroups}
              onRenderGroupHeader={renderGroupHeader}
              onLinkClick={(e, item) => {
                if (!item?.key) {
                  return;
                }
                setSelectedZone(JSON.parse(item?.key));
              }} />
          </div>
          <Stack>
            <CommandBar
              items={_items}
              ariaLabel="Items actions"
              primaryGroupAriaLabel="Items actions"
              farItemsGroupAriaLabel="More actions"
            />
            <Stack tokens={{ childrenGap: 5, padding: 14 }} style={{ display: selectedZone ? '' : 'none' }}>
              <Text variant="large" >Properties</Text>
              <Stack horizontal>
                <Label style={{ width: '75px' }}>Id: </Label>
                <TextField value={selectedZone?.id?.toString()} readOnly={true} disabled
                  borderless
                  name="name"
                  onChange={handleChange} />
              </Stack>
              <Stack horizontal>
                <Label style={{ width: '75px' }}>Name: </Label>
                <TextField value={selectedZone?.name} readOnly={!inEditMode} borderless={!inEditMode}
                  name="name"
                  onChange={handleChange} />
              </Stack>
              <Stack horizontal>
                <Label style={{ width: '75px' }}>Code: </Label>
                <TextField value={selectedZone?.code} readOnly={!inEditMode} borderless={!inEditMode}
                  name="code"
                  onChange={handleChange} />
              </Stack>
              <Text variant='large'>Asset Categories</Text>
              <div className={classNames.CheckboxListContainer} >
                {categories?.map(cat => (
                  <Stack horizontal>
                    <span className={classNames.CheckboxItem}
                      style={{ width: (cat.name.indexOf('/') * 14) }}>
                    </span>
                    {inEditMode ?
                      <Checkbox
                        id={cat.id?.toString()}
                        label={cat.name.substring(cat.name.indexOf('/') + 1)}
                        checked={!!selectedZone?.assetCategories?.find(ac => ac.id === cat.id)}
                        onChange={handleCategoriesChange}
                      /> :
                      <Stack horizontal>
                        <FontIcon className={classNames.CheckboxIconInNonEditMode}
                          iconName={!!selectedZone?.assetCategories?.find(ac => ac.id === cat.id) ? 'CheckboxComposite' : 'Checkbox'} />
                        <Text className={classNames.CheckboxSpansInNonEditMode}>{cat.name.substring(cat.name.indexOf('/') + 1)}</Text>
                      </Stack>}
                  </Stack>
                ))}
              </div>
              <Stack>
                <Stack.Item align="center" style={{ display: inEditMode ? '' : 'none' }}>
                  <Stack horizontal tokens={stackTokens}>
                    <PrimaryButton onClick={() => { handleSave() }}>
                      Save
                    </PrimaryButton>
                    <DefaultButton onClick={() => {
                      if (savedZone.current?.code !== selectedZone?.code ||
                        savedZone.current?.name !== selectedZone?.name) {
                        setConfirmDialogMessage("All your unsaved changes would be lost.");
                        showModal();
                      } else {
                        setInEditMode(false);
                      }
                    }}>Cancel</DefaultButton>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack>
          </Stack>
        </PanelSplitter>
      </Stack>
      <ConfirmDialog isModalOpen={isModalOpen} hideModal={hideModal}
        message={confirmDialogMessage}
        onYesClick={() => {
          if (inDeleteMode) {
            handleDelete();
            return;
          }
          hideModal();
          setSelectedZone(savedZone.current);
          setInEditMode(false);
        }} />

    </Stack>
  )
}

export default Zones

function filterChildZones(zones: IZone[], condition: (zone: IZone) => boolean, parentIncluded = true): IZone[] {
  const filteredZones: IZone[] = [];

  zones.forEach((zone) => {
    const childZones = zone.childZones ? filterChildZones(zone.childZones, condition, parentIncluded || condition(zone)) : [];

    if (condition(zone)) {
      if (!parentIncluded) {
        filteredZones.push(zone);
        parentIncluded = true;
      }
      zone.childZones = childZones;
      filteredZones.push(zone);
    } else if (childZones.length > 0) {
      zone.childZones = childZones;
      filteredZones.push(zone);
    }
  });

  return filteredZones;
}

function addChildZones(links: INavLink[], childZones: IZone[], isExpanded: boolean) {
  if (childZones === undefined) {
    return links;
  }
  for (const childZone of childZones) {
    const navLink: INavLink = {
      name: childZone.name || '', url: '',
      key: JSON.stringify({
        id: childZone.id, code: childZone.code, name: childZone.name,
        parentId: childZone.parentId, assetCategories: childZone.assetCategories
      }),
      isExpanded: isExpanded,
    };
    if (childZone.childZones) {
      const subLinks: INavLink[] = [];
      addChildZones(subLinks, childZone.childZones, isExpanded);
      navLink.links = subLinks;
    }
    links.push(navLink);
  }
}

