import { CommandBar, DetailsListLayoutMode, FontIcon, IColumn, ICommandBarItemProps, mergeStyleSets, Pivot, PivotItem, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { appRoles } from '../authConfig';
import { scrollStackItemStyles } from '../common/styles/StackStyles';
import { getAssetStatuses, getWorkOrderStatuses, IAssetStatuses, IWorkOrderStatuses } from '../services/assetServices';
import AppContext from './AppContext';
import { useAtomValue, useSetAtom } from 'jotai';
import { errorMessageAtom, isInProgressAtom } from '../atoms/messageBarAtoms';
import { profileDataAtom } from '../atoms/authAtoms';

const Statuses = () => {
  const context = useContext(AppContext);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);  const profileData = useAtomValue(profileDataAtom);

  const navigate = useNavigate();
  let { entityType } = useParams();

  const [shimmered, setShimmered] = useState(false);
  const [workOrderStatuses, setWorkOrderStatuses] = useState<IWorkOrderStatuses[]>([]);
  const [assetStatuses, setAssetStatuses] = useState<IAssetStatuses[]>([]);

  const fetchData = async () => {
    setIsInProgress(true);
    const abortController = new AbortController();
    try {
      if (entityType === 'workOrder') {
        context.setSelectedTab('WorkOrders statuses');
        const data: IWorkOrderStatuses[] = await getWorkOrderStatuses(abortController);

        setWorkOrderStatuses(data);
        setShimmered(false);
      } else if (entityType === 'asset') {
        context.setSelectedTab('Asset statuses');

        const data: IAssetStatuses[] = await getAssetStatuses(abortController);

        setAssetStatuses(data);
        setShimmered(false);
      }
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    }
    finally {
      setIsInProgress(false);
    }

    return () => {
      abortController.abort();
    }
  }

  useEffect(() => {
    fetchData();
  }, [entityType]);

  const _columns: IColumn[] = [
    {
      key: "Id",
      name: "Id",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 50,
      isResizable: true,
    },
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: "code",
      name: "Code",
      fieldName: "code",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "icon",
      name: "Icon",
      fieldName: "icon",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "color",
      name: "Color",
      fieldName: "color",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
    },
  ];

  const workOrderCellClass = mergeStyleSets({
    default: {
      padding: '5px',
    },
    bar: {
      height: '24px', display: 'block',
    }
  });

  const _renderItemColumn = (
    item: IWorkOrderStatuses,
    index?: number,
    column?: IColumn
  ) => {
    const fieldContent = item[column?.fieldName as keyof IWorkOrderStatuses] as string;

    switch (column?.key) {
      case "color":
        return item.color ? (
          <span
            className={workOrderCellClass.bar}
            style={{ backgroundColor: `${item.color}` }}
          ></span>
        ) : (
          <span></span>
        );
      case "icon":
        return item.icon ? (
          <FontIcon iconName={item?.icon} style={{ width: 25, height: 25, fontSize: 25 }} />
        ) : (
          <span></span>
        );
      default:
        return <span>{fieldContent}</span>;
    }
  };
  const _items: ICommandBarItemProps[] = [
    {
      key: "newWorkOrderStatuses",
      text: "New",
      iconProps: { iconName: "Add" },
      disabled: !profileData.roles.includes(appRoles.Admin),
      onClick: () => {
        navigate('/newWorkOrderStatuses');
      },
    },
  ];

  return (
    <>
      <Stack>
        <Pivot
          onLinkClick={(item?: PivotItem | undefined) => {
            navigate(`/statuses/${item?.props.itemKey ?? ''}`);
          }}
          defaultSelectedKey={entityType}>
          <PivotItem headerText='WorkOrders' itemKey='workOrder' />
          <PivotItem headerText='Assets' itemKey='asset' />
        </Pivot>
        <CommandBar
          items={_items}
          ariaLabel="Items actions"
          primaryGroupAriaLabel="Items actions"
          farItemsGroupAriaLabel="More actions"
        />
        <Stack>
          <Stack.Item align="center" styles={scrollStackItemStyles}>
            <ShimmeredDetailsList
              items={entityType === 'workOrder' ? workOrderStatuses : assetStatuses}
              columns={_columns}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              onRenderItemColumn={_renderItemColumn}
              selectionMode={SelectionMode.single}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
              enableShimmer={shimmered}
            />
          </Stack.Item>
        </Stack>
      </Stack>
    </>
  );
}

export default Statuses