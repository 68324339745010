import { appRoles } from '../../authConfig';
import PagedList from '../../common/PagedList'
import { getGroups } from '../../services/assetServices'
import { useAtomValue } from 'jotai';
import { profileDataAtom } from "../../atoms/authAtoms";

const Groups = () => {
  const profileData = useAtomValue(profileDataAtom);

  return (
    <PagedList selectedTab='Users'
      columns={[
        { key: 'id' },
        { key: 'name', minWidth: 200 },
      ]}
      getAction={getGroups}
      commandBarItems={[
        {
          key: "newGroup",
          text: "New",
          iconProps: { iconName: "Add" },
          // onClick: () => navigate("/newGroup"),
          disabled: !profileData.roles.includes(appRoles.Admin)
        },
        {
          key: "editGroup",
          text: "Edit",
          iconProps: { iconName: "Edit" },
          // onClick: () => navigate("/editGroup"),
          disabled: !profileData.roles.includes(appRoles.Admin)
        },
      ]}
    ></PagedList>
  )
}

export default Groups