import {
  CommandBar,
  DatePicker,
  DayOfWeek,
  DefaultButton,
  Dropdown,
  FontIcon,
  ICommandBarItemProps,
  Icon,
  IconButton,
  Link,
  MaskedTextField,
  mergeStyles,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  SearchBox,
  Separator,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from "@fluentui/react";
import {
  ColumnActionsMode,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IDetailsColumnProps,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import { Selection } from "@fluentui/react/lib/DetailsList";
import { ShimmeredDetailsList } from "@fluentui/react/lib/ShimmeredDetailsList";
import { useBoolean, useConst } from "@fluentui/react-hooks";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../AppContext";
import { cancelScheduledUpdateAssetStatus, getAssets, getAssetStatuses, getChartsData, getMetadata, getScheduledUpdateAssetStatus, IAsset, IAssetStatuses, IChatCommand, IPagedCollection, IScheduleActionRequest, IScheduledAction, IWorkOrderAssignmentResponse, scheduleUpdateAssetStatus } from "../../services/assetServices";
import { useNavigate, useParams } from "react-router-dom";
import { scrollStackItemStyles } from "../../common/styles/StackStyles";
import { appRoles } from "../../authConfig";
import React from "react";
import { createChatMessage } from "../ChatCommandDialog";
import { useAtomValue, useSetAtom } from "jotai";
import { errorMessageAtom, isInProgressAtom, messageBarAtom, successMessageAtom } from "../../atoms/messageBarAtoms";
import { profileDataAtom } from "../../atoms/authAtoms";

export interface IListItem {
  id: number;
  uuid: string;
  code: string;
  name: string;
}
export interface IListState {
  items: IListItem[];
  selectionDetails: string;
}

const sortIconClass = mergeStyles({
  fontSize: 12,
  paddingLeft: 5
});

const renderHeaderSortable = (props?: IDetailsColumnProps, defaultRender?: (props?: IDetailsColumnProps) => JSX.Element | null):
  JSX.Element | null => (!defaultRender ? <></> :
    <Stack horizontal verticalAlign="center">
      {defaultRender(props)}
      {props?.column.isSorted ? <></> :
        <FontIcon aria-label="Compass" iconName="Sort" className={sortIconClass} />
      }
    </Stack>);

const AssetsList = () => {
  let navigate = useNavigate();
  let { action } = useParams();

  const assetStatuses = useRef<IAssetStatuses[]>([]);
  const [allItems, setData] = useState<IAsset[]>([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState({ no: 1, search: "" });
  const [orderBy, setOrderBy] = useState<string>();
  const [shimmered, setShimmered] = useState(false);
  const context = useContext(AppContext);
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const setMessageBar = useSetAtom(messageBarAtom);
  const profileData = useAtomValue(profileDataAtom);
  const [scheduledActions, setScheduledActions] = useState<IScheduledAction[]>();

  const [scheduleAssetName, setScheduleAssetName] = useState<string>();
  const [scheduleAssetCode, setScheduleAssetCode] = useState<string>();
  const [scheduleDate, setScheduleDate] = useState<Date | undefined>();
  const [scheduleTime, setScheduleTime] = useState<string>();
  const [scheduleStatus, setScheduleStatus] = useState(assetStatuses.current?.length ? assetStatuses.current[0].id : 0);
  const [scheduleDescription, setScheduleDescription] = useState("");

  const sortableColumnClicked = (ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn): void | undefined => {
    orderBy === column.fieldName ? setOrderBy(`${column.fieldName} Desc`) : setOrderBy(column.fieldName);
  };

  const _columns: IColumn[] = [
    {
      key: "Id",
      name: "Id",
      fieldName: "id",
      isSorted: orderBy?.startsWith("id"),
      isSortedDescending: orderBy === "id Desc",
      columnActionsMode: ColumnActionsMode.clickable,
      onRenderHeader: renderHeaderSortable,
      onColumnClick: sortableColumnClicked,
      minWidth: 45,
      maxWidth: 50,
      isResizable: true,
      onRender: item => (
        <Link key={item.assetId} onClick={() => navigate(`/assetDetail?itemId=${item.id}`)}>
          {item.id}
        </Link >
      )
    },
    {
      key: "Code",
      name: "Code",
      fieldName: "code",
      isSorted: orderBy?.startsWith("code"),
      isSortedDescending: orderBy === "code Desc",
      columnActionsMode: ColumnActionsMode.clickable,
      onRenderHeader: renderHeaderSortable,
      onColumnClick: sortableColumnClicked,
      minWidth: 100,
      maxWidth: 125,
      isResizable: true,
    },
    {
      key: "category",
      name: "Category",
      fieldName: "category",
      isSorted: orderBy?.startsWith("category"),
      isSortedDescending: orderBy === "category Desc",
      columnActionsMode: ColumnActionsMode.clickable,
      onRenderHeader: renderHeaderSortable,
      onColumnClick: sortableColumnClicked,
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "AssetDate",
      name: "Asset Date",
      fieldName: "changeDate",
      isSorted: orderBy?.startsWith("changeDate"),
      isSortedDescending: orderBy === "changeDate Desc",
      columnActionsMode: ColumnActionsMode.clickable,
      onRenderHeader: renderHeaderSortable,
      onColumnClick: sortableColumnClicked,
      minWidth: 150,
      maxWidth: 175,
      isResizable: true,
    },
    {
      key: "Name",
      name: "Name",
      fieldName: "name",
      isSorted: orderBy?.startsWith("name"),
      isSortedDescending: orderBy === "name Desc",
      columnActionsMode: ColumnActionsMode.clickable,
      onRenderHeader: renderHeaderSortable,
      onColumnClick: sortableColumnClicked,
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: 'Status',
      name: "Status",
      fieldName: "statusId",
      minWidth: 75,
      maxWidth: 75,
      onRender: (item, index, column) => {
        const assetStatus = assetStatuses.current?.find(is => is.id === item.statusId);
        return (
          <Stack horizontal style={{
            borderBottomColor: `${assetStatus?.color}`,
            borderBlockStyle: 'solid',
            borderTopStyle: 'none'
          }}>
            <Stack.Item align="start">
              <span style={{ display: 'flex', justifyContent: 'left' }}>
                {assetStatus?.code}
              </span>
            </Stack.Item>
            <Stack.Item align="stretch" grow={1}><span style={{ width: '25px' }}></span> </Stack.Item>
            <Stack.Item align="end">
              <TooltipHost content={assetStatus?.name}>
                <FontIcon iconName={assetStatus?.icon} />
              </TooltipHost>
            </Stack.Item>
          </Stack>)
      }
    },
    {
      key: "zoneName",
      name: "Zone",
      fieldName: "zoneName",
      isSorted: orderBy?.startsWith("zoneName"),
      isSortedDescending: orderBy === "zoneName Desc",
      columnActionsMode: ColumnActionsMode.clickable,
      onRenderHeader: renderHeaderSortable,
      onColumnClick: sortableColumnClicked,
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
  ];

  const columns = useRef<IColumn[]>(_columns);

  const _images: Map<string, string> = new Map<string, string>();

  const fetchData = async () => {
    context.setSelectedTab("Assets");
    const abortController = new AbortController();
    setIsInProgress(true);
    try {
      assetStatuses.current = await getAssetStatuses(abortController);
      const metadata = await getMetadata(abortController, 'Asset');
      const otherInfoFields = metadata?.fields.filter(mf => mf.showInGrid);

      const imagePaths: string[] = [];

      const data: IPagedCollection<IAsset> = await getAssets(abortController, 10, page.no, orderBy, page.search);
      const _assets = (data?.items?.map(asset => {
        const newAsset: any = {
          ...asset,
          changeDate: new Date(asset.changeDate + 'Z').toLocaleString()
        };
        const otherInfoParts = asset.otherInfo?.split('|');
        if (otherInfoParts) {
          otherInfoFields?.forEach((mf, index) => {
            const part = otherInfoParts[index];
            newAsset[mf.name] = part;
            if (mf.fieldType === 'Image') {
              imagePaths.push(part.replace('attachments', 'thumbnails'));
            }
          });
        }
        otherInfoFields?.forEach(mf => {
          if (!columns.current?.find(column => column.name === mf.name)) {
            columns.current?.push({
              key: mf.name,
              name: mf.name,
              fieldName: mf.name,
              minWidth: 100,
              onRender: mf.fieldType === 'Image' ? (item: any) => (
                <img src={_images.get(item[mf.name].replace('attachments', 'thumbnails'))} style={{ width: 20 }} alt="" />
              ) : mf.fieldType === 'Date' ? (item: any) => (
                <span>{item[mf.name] ? new Date(item[mf.name]).toDateString() : ''}</span>
              ) : mf.fieldType === 'AutoComplete' ? (item: any) => (
                <span>{(item[mf.name] ?? ":").split(':')[1]}</span>
              ) : undefined
            })
          }
        });

        return newAsset;
      }));

      setData(_assets)

      setIsLastPage(data?.isLastPage);
      setPageCount(data?.pageCount);
      setShimmered(false);
      const chartData = await getChartsData(abortController, 'assets', '');
      context.setSelectedTab('Assets', chartData?.values, { xAxisFormat: (x: string) => `${x.substring(0, 4)}/${x.substring(4)}` });

      if (action === 'schedule') {
        const command = context.chatCommandContents;
        if (command.action === "schedule" && command.entityType === "asset") {
          const time = command.others["time" as keyof IChatCommand] as string;
          const [dateStr, timeStr] = time.split(' ');
          const [year, month, day] = dateStr.split('/');
          const [hours, mins] = timeStr.replace(/[a-p]m/, '').split(':');
          const adjHours = +hours + (timeStr.includes('pm') ? 12 : 0);
          setScheduleDate(new Date(dateStr));
          setScheduleTime((new Date(`${year}-${month}-${day}T${adjHours}:${mins}:00`)).toTimeString());
          const code = command["code" as keyof IChatCommand] as string;
          setScheduleAssetCode(code);
          const statusStr = command.others["status" as keyof IChatCommand] as string;
          const assetStatusId = assetStatuses.current?.find(as => as.name === statusStr)?.id;
          if (assetStatusId) {
            setScheduleStatus(assetStatusId);
          }
        }
        handleScheduleClick(true);
      }
    }
    catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    }
    finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    }
  };

  const fetchScheduledAction = async () => {
    try {
      const abortController = new AbortController();
      setIsInProgress(true);
      const scheduledActions = await getScheduledUpdateAssetStatus(abortController, context.selectedItem.id);
      setScheduledActions(scheduledActions ?? []);
    }
    catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    }
    finally {
      setIsInProgress(false);
    }
  }

  const [isSchedulePanelOpen, { setTrue: showSchedulePanel, setFalse: hideSchedulePanel }] = useBoolean(false);
  const [showScheduleChatHeader, setShowScheduleChatHeader] = useState(true);

  const handleScheduleClick = (fromChat: boolean) => {
    const selectedAsset = allItems?.find(as => as.code === context.selectedItem.code);
    setScheduleAssetName(selectedAsset?.name)
    setShowScheduleChatHeader(fromChat);
    fetchScheduledAction();
    showSchedulePanel();
  }

  useEffect(() => {
    fetchData();
  }, [page.no, page.search, orderBy, scheduleTime, scheduleAssetCode]);

  const _items: ICommandBarItemProps[] = [
    {
      key: "newItem",
      text: "New",
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "Add" },
      onClick: () => navigate("/newAsset"),
      disabled: !profileData.roles.includes(appRoles.Admin)
    },
    {
      key: "showQR",
      text: "Show QR",
      iconProps: { iconName: "QRCode" },
      onClick: () => {
        context.setSelectedItem({ id: 0, uuid: "" });
        navigate(`/showQR?itemId=${context.selectedItem.id}`);
      },
      disabled: context.selectedItem.uuid === "",
    },
    {
      key: "assetDetail",
      text: "Detail",
      iconProps: { iconName: "DocumentSet" },
      onClick: () => {
        context.setSelectedItem({ id: 0, uuid: "" });
        navigate(`/assetDetail?itemId=${context.selectedItem.id}`);
      },
      disabled: context.selectedItem.uuid === "",
    },
    {
      key: "assetEdit",
      text: "Edit",
      iconProps: { iconName: "Edit" },
      onClick: () => {
        context.setSelectedItem({ id: 0, uuid: "" });
        navigate(`/editAsset?id=${context.selectedItem.id}`);
      },
      disabled: !profileData.roles.includes(appRoles.Admin) || context.selectedItem.uuid === "",
    },
    {
      key: "schedule",
      text: "Schedule",
      iconProps: { iconName: "ScheduleEventAction" },
      onClick: () => handleScheduleClick(false),
      disabled: !profileData.roles.includes(appRoles.Admin) || context.selectedItem.uuid === "",
    },
    {
      key: "assetDelete",
      text: "Delete",
      iconProps: { iconName: "Delete" },
      onClick: () => {
        context.setSelectedItem({ id: 0, uuid: "" });
        navigate(`/assetDetail?action=delete&itemId=${context.selectedItem.id}`);
      },
      disabled: !profileData.roles.includes(appRoles.Admin) || context.selectedItem.uuid === "",
    },
  ];

  const [uuid, setUuid] = useState("");
  const _selection = new Selection({
    onSelectionChanged: () => {
      const sel = _getSelectionDetails();
      // console.log(sel);
      setUuid(sel.message);
      if (sel.item) {
        context.setSelectedItem(sel.item);
      }
    },
  });

  const _getSelectionDetails = () => {
    const selectionCount = _selection.getSelectedCount();

    switch (selectionCount) {
      case 0:
        return { count: 0, message: "No items selected" };
      case 1: {
        const firstSelection = _selection.getSelection()[0] as IListItem;
        return {
          count: 1,
          id: firstSelection.id,
          item: firstSelection,
          message: firstSelection.uuid,
        };
      }
      default:
        return {
          count: selectionCount,
          message: `${selectionCount} items selected`,
        };
    }
  };
  const today = useConst(new Date(Date.now()));

  const buttonStyles = { root: { marginRight: 8 } };

  return (
    <div>
      <Stack horizontal verticalAlign="center">
        <CommandBar
          items={_items}
          ariaLabel="Items actions"
          primaryGroupAriaLabel="Items actions"
          farItemsGroupAriaLabel="More actions"
        />
        <SearchBox
          placeholder="Search asset"
          onSearch={(newValue) => setPage({ no: 1, search: newValue || "" })}
          onClear={() => setPage({ no: 1, search: "" })} />
      </Stack>
      <Stack style={{ overflowY: 'scroll' }}>
        <Stack.Item align="start" styles={scrollStackItemStyles} >
          {allItems ?
            <ShimmeredDetailsList
              items={allItems}
              columns={columns.current}
              setKey="set"
              layoutMode={DetailsListLayoutMode.fixedColumns}
              selection={_selection}
              selectionMode={SelectionMode.single}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
              enableShimmer={shimmered}
            /> : <></>}
        </Stack.Item>
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Stack.Item grow={1} align="center">
          <IconButton
            iconProps={{ iconName: "DoubleChevronLeft" }}
            disabled={page.no === 1}
            onClick={() => setPage({ no: 1, search: page.search })}
          />
          <IconButton
            iconProps={{ iconName: "ChevronLeft" }}
            disabled={page.no === 1}
            onClick={() => setPage({ no: page.no - 1, search: page.search })}
          />
          <Text>
            {page.no} of {pageCount}
          </Text>
          <IconButton
            iconProps={{ iconName: "ChevronRight" }}
            disabled={isLastPage}
            onClick={() => setPage({ no: page.no + 1, search: page.search })}
          />
          <IconButton
            iconProps={{ iconName: "DoubleChevronRight" }}
            disabled={isLastPage}
            onClick={() => setPage({ no: pageCount, search: page.search })}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontalAlign="center">
        <TextField label="UUID" styles={{ root: { width: "400px" } }} readOnly value={uuid} />
      </Stack>
      <Panel
        isLightDismiss
        isOpen={isSchedulePanelOpen}
        isBlocking={false}
        onDismiss={hideSchedulePanel}
        closeButtonAriaLabel="Close"
        type={PanelType.medium}
        headerText="Select a status and a time in future"
        isFooterAtBottom={true}
      >
        <Stack tokens={{ childrenGap: 5 }}>
          <Stack styles={{ root: { display: showScheduleChatHeader ? '' : 'none' } }}>
            <Text variant="medium">Chat: </Text>
            <Text variant="small">{createChatMessage(context.chatCommandContents)}</Text>
          </Stack>
          <Text variant="small">Selected asset: </Text>
          <Text variant="large">{scheduleAssetName}</Text>
          <Text variant="small">Code:</Text>
          <Text variant="large">{scheduleAssetCode}</Text>

          <Dropdown
            options={assetStatuses.current?.map(as => ({ key: as.id, text: as.name, data: { icon: as.icon } }))}
            onRenderOption={(option): JSX.Element => (option ?
              <div>
                {option.data && option.data.icon && (
                  <Icon style={{ marginRight: '8px' }} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                )}
                <span>{option.text}</span>
              </div> : <></>)
            }
            onRenderTitle={(options): JSX.Element => (options && options[0] ?
              <div>
                {options[0].data && options[0].data.icon && (
                  <Icon style={{ marginRight: '8px' }} iconName={options[0].data.icon} aria-hidden="true" title={options[0].data.icon} />
                )}
                <span>{options[0].text}</span>
              </div> : <></>
            )}
            onChange={(event, option: any, index) => setScheduleStatus(Number.parseInt(option.key.toString()))}
            defaultSelectedKey={scheduleStatus}
          ></Dropdown>
          <Stack horizontal tokens={{ childrenGap: 5 }}>
            <DatePicker style={{ width: 175 }}
              firstDayOfWeek={DayOfWeek.Sunday}
              placeholder="Select a date..."
              ariaLabel="Select a date"
              minDate={today}
              onSelectDate={setScheduleDate as (date: Date | null | undefined) => void}
              value={scheduleDate}
            />
            <MaskedTextField mask='99:99'
              value={scheduleTime}
              onChange={(e, newValue) => { setScheduleTime(newValue ?? '00:00 am') }} />
          </Stack>
          <TextField
            label="Description (optional)" multiline rows={3}
            onChange={(e, newValue) => setScheduleDescription(newValue ?? '')} />
          <Stack horizontal>
            <PrimaryButton onClick={() => {
              const newDate = scheduleDate ?? new Date();
              if (scheduleTime) {
                const hour = Number.parseInt(scheduleTime.split(':')[0]) +
                  (scheduleTime.indexOf('pm') ? 12 : 0);
                const min = Number.parseInt(scheduleTime.split(':')[1].split(' ')[0]);
                newDate.setHours(hour);
                newDate.setMinutes(min);
                const newScheduledUpdate: IScheduleActionRequest = {
                  time: newDate,
                  statusId: scheduleStatus ?? 0,
                  description: scheduleDescription,
                };
                const abortController = new AbortController();
                scheduleUpdateAssetStatus(abortController, newScheduledUpdate, scheduleAssetCode ? undefined : context.selectedItem.id, scheduleAssetCode)
                  .then((data: IWorkOrderAssignmentResponse) => {
                    setSuccessMessage(`Scheduled [${data.workOrderAssignmentId}]`)
                  })
                  .catch((error: any) => setErrorMessage(error.message));
                hideSchedulePanel();
              } else {
                setErrorMessage("Please select Date and time");
              }
            }} styles={buttonStyles}>
              Save
            </PrimaryButton>
            <DefaultButton onClick={hideSchedulePanel}>Cancel</DefaultButton>
          </Stack>
          <Separator>Existing schedules</Separator>
          <DetailsList
            items={scheduledActions ?? []}
            columns={[{
              key: "time",
              name: "Time",
              fieldName: "scheduledTime",
              minWidth: 120,
              maxWidth: 120,
            },
            {
              key: "assetStatus",
              name: "Status",
              fieldName: "assetStatusId",
              minWidth: 70,
              maxWidth: 70,
              onRender: (item, index, column) => {
                const assetStatus = assetStatuses.current?.find(is => is.id === item.assetStatusId);
                return (
                  <Stack horizontal style={{
                    borderBottomColor: `${assetStatus?.color}`,
                    borderBlockStyle: 'solid',
                    borderTopStyle: 'none'
                  }}>
                    <Stack.Item align="start">
                      <span style={{ display: 'flex', justifyContent: 'left' }}>
                        {assetStatus?.code}
                      </span>
                    </Stack.Item>
                    <Stack.Item align="stretch" grow={1}><span style={{ width: '25px' }}></span> </Stack.Item>
                    <Stack.Item align="end">
                      <TooltipHost content={assetStatus?.name}>
                        <FontIcon iconName={assetStatus?.icon} />
                      </TooltipHost>
                    </Stack.Item>
                  </Stack>)
              }
            },
            {
              key: "actionStatus",
              name: "Result",
              fieldName: "actionStatus",
              minWidth: 160,
              maxWidth: 160,
            },
            {
              key: "action",
              name: "Action",
              fieldName: "",
              minWidth: 50,
              maxWidth: 50,
              onRender: (item, index, column) => {
                return (
                  <IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => {
                    var abortController = new AbortController();
                    cancelScheduledUpdateAssetStatus(abortController, item.id)
                      .then((data: string) => {
                        if (data.startsWith("WRN")) {
                          setMessageBar(data, MessageBarType.warning);
                        } else {
                          setSuccessMessage(data);
                        }
                      })
                      .catch((error: any) => setErrorMessage(error.message))
                  }} />
                )
              }
            },
            ]}
            compact={true}
            selectionMode={SelectionMode.none}
          />
        </Stack>
      </Panel>
    </div>
  );
};

export default AssetsList;
