import { IStackStyles, ITextFieldStyles, Pivot, PivotItem, Stack, TextField } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSettings, ISettings } from "../../services/assetServices";
import AppContext from "../AppContext";

const Settings = () => {
  const navigate = useNavigate();

  const [clientUrl, setClientUrl] = useState("");

  const context = useContext(AppContext);

  useEffect(() => {
    context.setSelectedTab("Settings");
    const abortController = new AbortController();

    getSettings(abortController).then((data: ISettings) => {
      setClientUrl(data?.clientUrl);
    });

    return () => {
      abortController.abort();
    }
  }, []);

  const textFieldStyles: Partial<ITextFieldStyles> = {
    fieldGroup: { width: 300 },
    root: { padding: 10 }
  };

  const stackStyles: IStackStyles = {
    root: {
      label: {
        display: 'flex',
        paddingLeft: 10
      }
    }
  }
  return (
    <Stack styles={stackStyles} horizontalAlign="stretch">
      <Pivot defaultSelectedKey='client' onLinkClick={(item) => {
        if (item?.props.itemKey === 'back') {
          navigate('/');
        }
      }}>
        <PivotItem itemIcon='ChromeBack' itemKey='back' />
        <PivotItem headerText="Client" itemKey="client">
          <TextField
            label="ClientUrl"
            value={clientUrl}
            styles={textFieldStyles}
            readOnly
          />
        </PivotItem>
        <PivotItem headerText="Other">
        </PivotItem>
      </Pivot>
    </Stack>
  );
};

export default Settings;
