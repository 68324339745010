import { DefaultButton, TextField, DialogFooter, DialogType, Dialog, ITextField, Stack, IStackStyles, DefaultPalette, IStackItemStyles, IStackTokens, IconButton, ContextualMenu } from '@fluentui/react'
import { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { chat2Command, IChat2CommandResponse } from '../services/assetServices';
import AppContext from './AppContext';

export interface IChatCommandDialogParams {
  toggleHideDialog: () => void;
  hideDialog: boolean | undefined;
}

export const createChatMessage = (response: IChat2CommandResponse): string => {
  if (response.error) {
    return `Some error happened: ${response.error}`;
  }
  return `Ok, ${response.action}ing ${response.entityType}. Please review and submit. ${(response.schemaValidationErrors?.length) ? `please note: ${response.schemaValidationErrors.join(',')}` : ''
    }`;
}

const ChatCommandDialog = (params: IChatCommandDialogParams) => {
  const dialogContentProps = {
    type: DialogType.largeHeader,
    title: 'Chat to Command (Powered by ChatGPT)',
  };

  const navigate = useNavigate();
  const context = useContext(AppContext);
  const textRef = useRef<ITextField>(null);

  interface IChatMessage {
    source: 'chat' | 'user';
    message: string;
  }

  const defaultChatMessages: IChatMessage[] = [
    { source: 'chat', message: 'Hi, Enter your command as plain english' }
  ];
  const [chatMessages, setChatMessages] = useState<IChatMessage[]>(defaultChatMessages);

  const [lastMessage, setLastMessage] = useState("");

  const handleOkClick = () => {
    setChatMessages(prevVal => ([...prevVal
      , { message: textRef.current?.value ?? '', source: 'user' }
    ]));
    setLastMessage("");
    const abortController = new AbortController();
    chat2Command(abortController, { text: textRef.current?.value ?? '' })
      .then((data: IChat2CommandResponse) => {
        if (data.error) {
          alert(data.error);
        } else {
          setChatMessages(prevVal => ([...prevVal
            , { message: createChatMessage(data), source: 'chat' }
          ]));
          context.setChatCommandContents(data);
          if (data.entityType === "contractor" && data.action === 'add') {
            navigate('/newContractor')
          } else if (data.entityType === "contractor" && data.action === 'edit') {
            navigate('/editContractor?id=0')
          } else if (data.entityType === "asset" && data.action === 'add') {
            navigate('/newAsset');
          } else if (data.entityType === "asset" && data.action === 'edit') {
            navigate('/editAsset?id=0');
          } else if (data.entityType === "asset" && data.action === 'schedule') {
            navigate('/assets/schedule');
          }
        }
      });
  }
  const handleCloseClick = () => {
    setChatMessages(defaultChatMessages);
    params.toggleHideDialog();
  }

  const stackStyles: IStackStyles = {
    root: {
      height: 150,
      overflow: 'scroll',
      borderColor: DefaultPalette.blue,
      borderStyle: 'solid',
      borderWidth: 1,
    },
  };
  const chatStackItemStyles: IStackItemStyles = {
    root: {
      background: DefaultPalette.themeSecondary,
      color: DefaultPalette.white,
      padding: 5,
      width: '80%',
    },
  };
  const userStackItemStyles: IStackItemStyles = {
    root: {
      background: DefaultPalette.themeLight,
      padding: 5,
      width: '80%',
    },
  };
  const itemAlignmentsStackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10,
  };
  const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };
  return (
    <Dialog
      hidden={params.hideDialog}
      onDismiss={params.toggleHideDialog}
      dialogContentProps={dialogContentProps}
      maxWidth={550}
      modalProps={{ dragOptions: dragOptions }}
    >
      <Stack tokens={itemAlignmentsStackTokens}>
        <Stack styles={stackStyles} tokens={itemAlignmentsStackTokens}>
          {chatMessages?.map(msg => msg.source === 'chat' ?
            <Stack.Item align="start" styles={chatStackItemStyles}>
              <span>{msg.message}</span>
            </Stack.Item>
            :
            <Stack.Item align="end" styles={userStackItemStyles}>
              <span>{msg.message}</span>
            </Stack.Item>
          )}
        </Stack>
        <Stack horizontal>
          <TextField width={450} componentRef={textRef} styles={{ root: { width: '100%' } }}
            value={lastMessage}
            onChange={(_, newValue) => setLastMessage(newValue ?? '')}
          />
          <IconButton iconProps={{ iconName: 'Send' }}
            onClick={handleOkClick} />
        </Stack>
      </Stack>
      <DialogFooter>
        <DefaultButton //onClick={params.toggleHideDialog} 
          onClick={handleCloseClick}
          text="Close" />
      </DialogFooter>
    </Dialog>
  )
}

export default ChatCommandDialog