// import "./CreateItem.css";
import { useContext, useEffect, useRef, useState } from "react";
import {
  DefaultButton,
  PrimaryButton,
  Label,
  TagPicker,
  ITag,
  IBasePickerSuggestionsProps,
  IBasePicker,
  Separator,
  SelectionMode,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { ITextField, TextField } from "@fluentui/react/lib/TextField";
import { Stack, IStackStyles } from "@fluentui/react/lib/Stack";
import { useLocation, useNavigate } from "react-router-dom";
import AppContext, { defaultChatCommand } from "../AppContext";
import ConfirmDialog from "../../common/ConfirmDialog";
import { createContractor, editContractor, getContractor, getMetadata, getWorkOrderAssignment, IChatCommand, IContractor, ICreateContractorRequest, ICreateContractorResponse, IMetadata, IPagedCollection, IUpdateContractorRequest, IWorkOrder, IWorkOrderStatuses, searchWorkOrders }
  from "../../services/assetServices";
import React from "react";
import WorkItems from "../workOrders/WorkItems";
import OtherInfo from "../../common/OtherInfo";
import { columnProps, labelColumnStyle, valueColumnStyle } from "../../common/styles/FormsStyles";
import { useSetAtom } from "jotai";
import { errorMessageAtom, isInProgressAtom, successMessageAtom } from "../../atoms/messageBarAtoms";
import { IValidationError, validate } from "../../common/ValidationHelper";

const Contractor = () => {
  const query = new URLSearchParams(useLocation().search);
  const contractorId = Number.parseInt(query.get("id") ?? "");

  // const [categories, setCategories] = useState<IContractorCategory[]>();
  const navigate = useNavigate();

  const codeInput = useRef<ITextField>(null);
  const nameInput = useRef<ITextField>(null);
  const workOrdersInput = useRef<IBasePicker<ITag>>(null);
  const assignmentsDescriptionInput = useRef<ITextField>(null);

  const isSaved = useRef(false);

  interface IAddedWorkOrder {
    id: number;
    description: string;
  }

  const [metadata, setMetadata] = useState<IMetadata>();
  const [contractor, setContractor] = useState<IContractor>();
  const [workOrders, setWorkOrders] = useState<IWorkOrder[]>([]);
  const [addedWorkOrders, setAddedWorkOrders] = useState<IAddedWorkOrder[]>([]);
  const pageSize = 10;
  const [pageNo, setPageNo] = useState(1);
  const [errors, setErrors] = useState<IValidationError[]>();

  const context = useContext(AppContext);
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const fetchData = async () => {
    context.setSelectedTab("Contractors");

    const abortController = new AbortController();
    setIsInProgress(true);
    try {
      const metadata = await getMetadata(abortController, 'Contractor');
      setMetadata(metadata);
      if (contractorId) {
        const contractor = await getContractor(abortController, contractorId);
        setContractor(contractor);
        setOtherInfo(JSON.parse(contractor.otherInfo));
        const workOrders = await getWorkOrderAssignment(abortController, contractorId, pageSize, pageNo);
        setWorkOrders(workOrders.items);
      }
      const command = context.chatCommandContents;
      if (command.action === "add" && command.entityType === "contractor") {
        const otherInfo: any = {};
        metadata?.fields.forEach(f => {
          const fieldName = f.name;
          if (command.hasOwnProperty(fieldName)) {
            const valueFromCommand = command[fieldName as keyof IChatCommand] as string;
            otherInfo[fieldName] = valueFromCommand;
          } else if (command.others.hasOwnProperty(fieldName)) {
            const valueFromCommand = command.others[fieldName as keyof IChatCommand] as string;
            otherInfo[fieldName] = valueFromCommand;
          }
        });
        const contractor = {
          name: context.chatCommandContents.name ?? '', code: context.chatCommandContents.code ?? '', id: 0,
          workOrders, createdAt: new Date().toString(), otherInfo: JSON.stringify(otherInfo)
        }
        setContractor(contractor);
        setOtherInfo(JSON.parse(contractor.otherInfo));
        context.setChatCommandContents(defaultChatCommand);
      } else if (command.action === "edit" && command.entityType === "contractor") {
        const contractor = await getContractor(abortController, undefined, command.code);
        contractor.name = context.chatCommandContents.name ?? contractor.name;
        const otherInfo: any = JSON.parse(contractor.otherInfo);
        metadata?.fields.forEach(f => {
          const fieldName = f.name;
          if (command.hasOwnProperty(fieldName)) {
            const valueFromCommand = command[fieldName as keyof IChatCommand] as string;
            otherInfo[fieldName] = valueFromCommand ? valueFromCommand : otherInfo[fieldName];
          } else if (command.others.hasOwnProperty(fieldName)) {
            const valueFromCommand = command.others[fieldName as keyof IChatCommand] as string;
            otherInfo[fieldName] = valueFromCommand ? valueFromCommand : otherInfo[fieldName];
          }
        });
        contractor.otherInfo = JSON.stringify(otherInfo);
        setContractor(contractor);
        setOtherInfo(JSON.parse(contractor.otherInfo));
        const workOrders = await getWorkOrderAssignment(abortController, contractor.id, pageSize, pageNo);
        setWorkOrders(workOrders.items);
        context.setChatCommandContents(defaultChatCommand);
      }
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    }
  }

  useEffect(() => {
    fetchData()
  }, []);

  const [otherInfo, setOtherInfo] = useState({});

  const handleSubmit = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    if (metadata) {
      const errs = validate(contractor, metadata.validations, metadata.fields);
      setErrors(errs);
      
      if (errs.length > 0) {
        return;
      }
    }
    setIsInProgress(false);

    const abortController = new AbortController();
    if (contractorId && contractor) {
      const data: IUpdateContractorRequest = {
        code: contractor.code,
        name: contractor.name,
        otherInfo: JSON.stringify(otherInfo),
        workOrdersIds: workOrders?.map(wo => wo.id).concat(addedWorkOrders?.map(awo => awo.id)),
        description: assignmentsDescriptionInput.current?.value,
      };
      editContractor(abortController, contractorId, data)
        .then(() => {
          setSuccessMessage(`Contractor ID:${contractorId} updated`);
          setAddedWorkOrders([]);
          workOrdersInput.current?.items?.splice(0, workOrdersInput.current?.items?.length);
          getWorkOrderAssignment(abortController, contractorId, pageSize, pageNo)
            .then((data: IPagedCollection<IWorkOrder>) => { setWorkOrders(data.items) });
          isSaved.current = true;
        })
        .finally(() => {
          setIsInProgress(false);
        });
      return;
    }
    const data: ICreateContractorRequest = {
      code: (codeInput?.current as unknown as ITextField)?.value ?? '',
      name: (nameInput?.current as unknown as ITextField)?.value ?? '',
      workOrdersIds: (workOrdersInput?.current as unknown as IBasePicker<ITag>)
        .items?.map(item => (Number)(item.key)) ?? [],
      otherInfo: JSON.stringify(otherInfo),
      assignmentsDescription: (assignmentsDescriptionInput?.current as unknown as ITextField).value ?? '',
    };
    setIsInProgress(true);
    createContractor(abortController, data)
      .then((data: ICreateContractorResponse) => {
        setSuccessMessage(`${data.contractorId ? "Contractor created." : "Contractor with this code already exists"} Contractor ID:${data.contractorId}`);
        isSaved.current = true;
      })
      .catch((error: any) => {
        console.error("Error:", error);
        setErrorMessage(error.message);
      })
      .finally(() => {
        setIsInProgress(false);
      });
  };

  const stackTokens = { childrenGap: 50 };
  const stackStyles: Partial<IStackStyles> = { root: { width: 650 } };

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'WorkOrders',
    noResultsFoundText: 'No workorder found',
  };

  const listContainsTagList = (tag?: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0 || tag === undefined) {
      return false;
    }
    return tagList.some(compareTag => compareTag.key === tag.key);
  };

  const onItemSelected = React.useCallback((item?: ITag): ITag | null => {
    if (workOrdersInput.current && listContainsTagList(item, workOrdersInput.current.items)) {
      return null;
    }
    setAddedWorkOrders((prevWorkOrders: any) => {
      const newWorkOrders: IAddedWorkOrder[] = [...prevWorkOrders];
      const newWorkOrder: IAddedWorkOrder = {
        id: Number.parseInt(item?.key.toString() ?? ''),
        description: item?.name ?? '',
      };
      newWorkOrders.push(newWorkOrder);
      return newWorkOrders;
    });
    return item ?? null;
  }, []);

  async function onResolveSuggestions(filter: string): Promise<ITag[]> {
    const abortController = new AbortController();
    if (!filter || filter.length < 3) {
      return [];
    }
    const suggestions = await searchWorkOrders(abortController, filter);
    if (suggestions.length === 0) {
      return [];
    }
    return suggestions?.map(s => ({ key: s.id, name: s.description }));
  }

  const getTextFromItem = (item: ITag) => item.key.toString();

  const handleChange = (fieldName: string, value?: string) => {
    setContractor((prev: any) => {
      const newContractor = { ...prev };
      newContractor[fieldName] = value;
      return newContractor;
    });
  };

  const [selectedWorkOrders, setSelectedWorkOrders] = useState<IWorkOrder[]>([]);
  const [shimmered, setShimmered] = useState(false);
  const [workOrderStatuses, setWorkOrderStatuses] = useState<IWorkOrderStatuses[]>([]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div style={{ padding: "10px" }}>
          <div className="form-group">
            <Stack horizontal tokens={stackTokens} styles={stackStyles}>
              <Stack {...columnProps}>
                <Stack tokens={{ childrenGap: 5 }}>
                  <Stack horizontal>
                    <Label style={labelColumnStyle}>Name: </Label>
                    <TextField style={valueColumnStyle} value={contractor?.name} componentRef={nameInput} readOnly={isSaved.current}
                      onChange={(_, newValue) => handleChange('name', newValue)}
                      errorMessage={errors?.find(e => e.fieldName === 'name')?.message}
                    />
                  </Stack>
                  <Stack horizontal>
                    <Label style={labelColumnStyle}>Code: </Label>
                    <TextField style={valueColumnStyle} value={contractor?.code} componentRef={codeInput} readOnly={isSaved.current}
                      onChange={(_, newValue) => handleChange('code', newValue)}
                      errorMessage={errors?.find(e => e.fieldName === 'code')?.message}
                    />
                  </Stack>
                  {metadata ?
                    <OtherInfo metadata={metadata} otherInfo={JSON.stringify(otherInfo)} isSaved={isSaved}
                      onOtherInfoChanged={(newValue: string) => setOtherInfo(newValue)} validationErrors={errors} /> :
                    <></>}
                  <Separator>Assignments</Separator>
                  <Stack horizontal>
                    <Label style={labelColumnStyle}>Work orders: </Label>
                    <TagPicker
                      styles={{ root: valueColumnStyle }}
                      removeButtonAriaLabel="Remove"
                      selectionAriaLabel="Selected work orders"
                      onResolveSuggestions={onResolveSuggestions}
                      getTextFromItem={getTextFromItem}
                      pickerSuggestionsProps={pickerSuggestionsProps}
                      itemLimit={5}
                      // this option tells the picker's callout to render inline instead of in a new layer
                      pickerCalloutProps={{ doNotLayer: true }}
                      componentRef={workOrdersInput}
                      onItemSelected={onItemSelected}
                    />
                  </Stack>
                  <Stack horizontal>
                    <Label style={labelColumnStyle}>Description: </Label>
                    <TextField style={valueColumnStyle} componentRef={assignmentsDescriptionInput} readOnly={isSaved.current} />
                  </Stack>
                  <Label>Currently assigned work orders</Label>
                  <WorkItems issueWorkOrders={workOrders} workOrderStatuses={workOrderStatuses}
                    issue={undefined}
                    listSelectionMode={SelectionMode.none}
                    selection={selectedWorkOrders}
                    setSelection={setSelectedWorkOrders}
                    showRemoveColumn={true}
                    onRemoveButtonClicked={(workOrder: IWorkOrder) => {
                      setWorkOrders((prev: any) => {
                        const newWorkOrders: IWorkOrder[] = [...prev].filter(wo => wo.id !== workOrder.id);
                        return newWorkOrders;
                      });
                    }}
                    shimmered={shimmered} />
                </Stack>
                <Stack.Item align="center">
                  <Stack horizontal tokens={stackTokens}>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                    <DefaultButton onClick={() => {
                      if (!isSaved.current) {
                        showModal();
                      } else {
                        navigate("/contractors");
                      }
                    }}>Cancel</DefaultButton>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack>

            <ConfirmDialog isModalOpen={isModalOpen} hideModal={hideModal}
              message="All your unsaved changes would be lost."
              onYesClick={() => navigate("/contractors")} />

          </div>
        </div>
      </form>{" "}
    </div>
  );
};

export default Contractor;
