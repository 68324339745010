import { Link, Panel, PanelType, SearchBox, DetailsList, SelectionMode } from '@fluentui/react';
import { useState } from 'react'
import { getAssets, IPagedCollection, IAsset } from '../../services/assetServices';

export interface IAssetSelectorProps {
  isOpen: boolean;
  hideAssetSelector: any;
  onAssetLinkClick: (assetId: number) => void;
}

const AssetSelector = (props: IAssetSelectorProps) => {

  const [assets, setAssets] = useState<IAsset[]>([]);

  return (
    <Panel
      isLightDismiss
      isOpen={props.isOpen}
      onDismiss={props.hideAssetSelector}
      closeButtonAriaLabel="Close"
      type={PanelType.medium}
      headerText="Pick asset for selected issues"
    >
      <SearchBox
        placeholder="Search asset"
        onSearch={(newValue) => {
          const abortController = new AbortController();
          getAssets(abortController, 10, 1, undefined, newValue)
            .then((assets: IPagedCollection<IAsset>) => {
              setAssets(assets.items)
            })
        }}
        onClear={() => setAssets([])} />
      <DetailsList
        columns={[
          {
            key: "Id",
            name: "Id",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            onRender: item => (
              <Link key={item.assetId}
                onClick={() => props.onAssetLinkClick(item.id)}
              >
                {item.id}
              </Link >
            )
          },
          {
            key: "Code",
            name: "Code",
            fieldName: "code",
            minWidth: 100,
            maxWidth: 125,
            isResizable: true,
          },
          {
            key: "category",
            name: "Category",
            fieldName: "category",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
          },
          {
            key: "Name",
            name: "Name",
            fieldName: "name",
            minWidth: 250,
            maxWidth: 250,
            isResizable: true,
          },
        ]}
        compact={true}
        items={assets}
        selectionMode={SelectionMode.none}
      />
    </Panel>
  )
}

export default AssetSelector