import { getAuthHeader } from "./authHelper";

export interface IMessage {
  t: string; // type
  l: number; // level
  b: string; // body
}

export interface IAssetDetails {
  asset: IAsset;
  issues: IIssue[];
  qrCode: IQrCode;
}

export interface supportedIssue {
  name: string;
  id: string;
}
export interface IAsset {
  changeDate?: string;
  code: string;
  createdAt?: string;
  id: number;
  name: string;
  uuid: string;
  category: string;
  categoryId: number;
  zoneId?: number;
  statusId?: number;
  supportedIssues?: supportedIssue[];
  otherInfo?: string;
}

export interface IUser {
  id: number;
  userName: string;
  firstName?: string;
  lastName?: string;
  email: string;
  createdAt?: string;
}

export interface IGroup {
  id: number;
  name: string;
}

export interface IAction {
  id: number;
  name: string;
}

export interface IPermission {
  id: number;
  name: string;
  actionId: number;
  userId?: number;
  groupId?: number;
}

export interface IUpdatePermission {
  userId?: number;
  groupId?: number;
  actions: number[];
}

export interface IUpdatePermissionsRequest {
  permissions: IUpdatePermission[];
}

export interface IUpdatePermissionsResponse {
  message: string;
}

export interface IQrCode {
  assetId: number;
  createdAt?: string;
  data: string;
  id: number;
}

export interface IImportedAsset {
  code: string;
  id: number;
  ChangedDate: Date;
}

export interface IImportResult {
  importedItems: IImportedAsset[];
  logFilePath: string;
}

export interface IIssue {
  id: number;
  comment: string;
  imageUrl: string;
  image: string;
  workOrdersCount: number;
  createdAt: string;
  otherInfo: string;
  zoneId: number;
  assetId: number;
  uuid: string;
}
export interface IField {
  name: string;
  fieldType: string;
  label: string;
  lookupList?: string;
  reference?: string;
  dependees?: string;
  showInGrid: boolean;
}
export interface ILookupValue {
  key: string;
  value: string;
}
export interface ILookup {
  name: string;
  values: ILookupValue[];
}
export interface IValidation {
  field: string;
  rules: string[];
}
export interface IMetadata {
  lookups: ILookup[];
  fields: IField[];
  validations: IValidation[];
}
export interface IUpdateMetadataResponse {
  version: number;
}
export interface IUploadFileRequest {
  name: string;
  content: string;
}
export interface IUploadFileResponse {
  url: string;
}
export interface IContractor {
  id: number;
  name: string;
  code: string;
  workOrders: any;
  createdAt: string;
  otherInfo: string;
}
export interface IWorkOrder {
  id: number;
  issueId: number;
  code: string;
  description: string;
  status: string;
  statusId: number;
  statusDescription: string;
  createdAt: string;
}
export interface IZone {
  id?: number;
  code?: string;
  name?: string;
  childZones?: IZone[],
  parentId?: number;
  assetCategories?: IAssetCategory[]
}

export interface ICreateZoneRequest {
  code: string;
  name: string;
  parentId?: number;
  assetCategories?: number[];
}

export interface ICreateCategoryRequest {
  code: string;
  name: string;
  parentId?: number;
  issueTypes?: IIsueType[];
}

export interface IChartData {
  values: Map<string, number>;
}

export interface IPagedCollection<itemType> {
  isLastPage: boolean;
  items: itemType[];
  pageCount: number;
  pageNo: number;
  pageSize: number;
  rowsCount: number;
}

export interface IImage {
  imageContent: string;
}

export interface ICreateAssetRequest {
  uuid: string;
  code: string;
  name: string;
  categoryId: number;
  zoneId: number;
  statusId?: number;
  changedate: string;
  otherInfo?: string;
}

export interface IUpdateAssetRequest {
  code: string | undefined;
  name: string | undefined;
  categoryId: number | undefined;
  zoneId?: number;
  statusId?: number;
  changedate: string | undefined;
  otherInfo?: string;
}
export interface IUpdateContractorRequest {
  name: string;
  code: string;
  workOrdersIds: number[];
  otherInfo: string;
  description?: string;
}

export interface IUpdateZoneRequest {
  code: string | undefined;
  name: string | undefined;
  parentId: number | undefined;
  assetCategories?: number[]
}
export interface IUpdateIssueRequest {
  assetId: number | undefined;
}
export interface IUpdateCategoryRequest {
  code: string | undefined;
  name: string | undefined;
  parentId: number | undefined;
  issueTypes?: IIsueType[];
}
export interface ICreateAssetResponse {
  assetId: number;
  qrCodeId: number;
  created: boolean;
}
export interface ICreateContractorRequest {
  name: string;
  code: string;
  workOrdersIds: number[];
  otherInfo: string;
  assignmentsDescription: string;
}
export interface IWorkOrderAssignmentRequest {
  contractorId: number;
  workOrderId: number;
  description: string;
}

export interface IScheduleActionRequest {
  time: Date;
  statusId: number;
  description: string;
}

export interface ICreateZoneResponse {
  zoneId: number;
}
export interface ICreateContractorResponse {
  contractorId: number;
}
export interface IWorkOrderAssignmentResponse {
  workOrderAssignmentId: number
}
export interface ICreateCategoryResponse {
  assetCategoryId: number;
}
export interface IResendWorkOrderResponse {
  workOrderId: number;
}
export interface ILastImport {
  lastImport: { lastImportedDateTime: string; lastImportedId: string, createdAt: string };
  latestImportedAsset: { code: string, changeDate: string };
  latestAssetInSourceDate: string;
  latestAssetInSourceNum: string;
}

export interface IAssetCategories2IssueTypesRequest {
  csvContent: string;
}

export interface IImportZoneRequest {
  csvContent: string;
}

export interface IChat2CommandRequest {
  text: string;
}

export interface IChatCommand {
  action: string;
  entityType: string;
  code?: string;
  name?: string;
  others?: any;
  schemaValidationErrors?: string[];
}

export interface IChat2CommandResponse extends IChatCommand {
  error?: string;
}

export interface IAssetCategories2IssueTypesResponse {
  isSuccess: Boolean;
  errors: string[];
}

export interface IImportZonesResponse {
  isSuccess: Boolean;
  errors: string[];
  importedZones: string[];
  logFilePath: string;
}

export interface ISettings {
  clientUrl: string;
}

export interface IWorkOrderStatuses {
  id: number;
  name: string;
  code: string;
  color: string;
  icon: string;
}

export interface IAssetStatuses {
  id: number;
  name: string;
  code: string;
  color: string;
  icon: string;
}

export interface IScheduledAction {
  uuid: string;
  entityType: string;
  scheduledTime: Date;
  actionStatus: string;
  assetId: number;
  assetStatusId: number;
  workOrderId: number;
  workOrderStatusId: number;
  description: string;
  createdDate: Date;
  id: number;
  createdAt: Date;
}

export interface IIsueType {
  id?: number;
  code: string;
  name: string;
  priority?: string;
}

export interface IReport {
  type: string;
  title: string;
  source: string;
}

export interface IAssetCategory {
  id?: number;
  code: string;
  name: string;
  subCategories?: IAssetCategory[],
  parentId?: number;
  issueTypes?: IIsueType[];
}

export interface authorizeRequest {
  username: string;
}

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

const internalFetch = async (
  url: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  abortController: AbortController,
  body?: string,
  noJsonResponse?: boolean,
  retry?: number,
): Promise<any> => {
  const headers = await getAuthHeader();

  const signal = abortController?.signal;
  const options = {
    method: method,
    headers: headers,
    timeout: 3000,
    body: body,
    signal: signal
  };

  for (let attempt = 0; attempt < (retry ?? 1); attempt++) {
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return Promise.resolve(response).then(
          (response) => (noJsonResponse ? response : response.json()),
          (reason: any) => {
            const errorMessage: string = reason.errorMessage || "";
            if (errorMessage.includes("AADSTS50058")) {
              throw new Error("Authentication token expired, please sign in again");
            }
          }
        );
      } else {
        if (attempt === retry) {
          return Promise.reject(response);
        }
        console.log(`response:${response.status} in attempt: ${attempt}`)
        await sleep(500);
      }
    } catch (error) {
      if (!signal.aborted) {
        if (attempt === retry) {
          console.log(error);
          return Promise.reject(error);
        }
        console.log(`fail in attempt: ${attempt}`);
        await sleep(500);
      }
    }
  }
};

export const authorize = async (
  abortController: AbortController,
  payload: authorizeRequest,
): Promise<number[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/authorize?code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "POST",
    abortController,
    JSON.stringify(payload),
    undefined, 
    3,
  )
}

export const getAssets = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number,
  orderBy?: string,
  search?: string
): Promise<IPagedCollection<IAsset>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/assets?pageSize=${pageSize}&pageNo=${pageNo}${orderBy ? `&orderBy=${orderBy}` : ""}${search ? `&search=${search}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getUsers = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number,
  orderBy?: string,
  search?: string
): Promise<IPagedCollection<IUser>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/users?pageSize=${pageSize}&pageNo=${pageNo}${orderBy ? `&orderBy=${orderBy}` : ""}${search ? `&search=${search}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getGroups = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number,
  orderBy?: string,
  search?: string
): Promise<IPagedCollection<IGroup>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/groups?pageSize=${pageSize}&pageNo=${pageNo}${orderBy ? `&orderBy=${orderBy}` : ""}${search ? `&search=${search}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};
export const getPermissions = async (
  abortController: AbortController,
  userId?: number,
  groupId?: number,
  actionId?: number,
): Promise<IPermission[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/permissions?${userId ? `userId=${userId}` : groupId ? `groupId=${groupId}` : actionId ? `actionId=${actionId}` : ''
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getActions = async (
  abortController: AbortController,
): Promise<IAction[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/actions?code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const updatePermissions = async (
  abortController: AbortController,
  permissions: IUpdatePermissionsRequest
): Promise<IUpdatePermissionsResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/permissions?code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(permissions),
  )
}

export const getChartsData = async (
  abortController: AbortController,
  entityType: string,
  options?: string
): Promise<IChartData> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/chart/${entityType}?options=${options}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getWorkOrderAssignment = async (
  abortController: AbortController,
  contractorId: number,
  pageSize: number,
  pageNo: number,
  search?: string
): Promise<IPagedCollection<IWorkOrder>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/workOrderAssignment?contractorId=${contractorId}&pageSize=${pageSize}&pageNo=${pageNo}${search ? `&search=${search}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getIssue = async (
  abortController: AbortController,
  issueId: number,
): Promise<IIssue> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/issue?issueId=${issueId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getIssues = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number,
  search?: string,
  filters?: string,
): Promise<IPagedCollection<IIssue>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/issues?pageSize=${pageSize}&pageNo=${pageNo}${search ? `&search=${search}` : ""
    }${filters ? `&filters=${filters}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const editIssue = async (
  abortController: AbortController,
  issueId: number,
  issue: IUpdateIssueRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/issueById?issueId=${issueId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(issue),
  )
}

export const getMetadata = async (
  abortController: AbortController,
  entityType?: string
): Promise<IMetadata> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/metadata?entity=${entityType}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getReferences = async (
  abortController: AbortController,
  entityType?: string
): Promise<string[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/references?code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getAutoCompleteValues = async (
  abortController: AbortController,
  refName: string,
  text: string,
  dependees?: string,
): Promise<{key: string, value: string}[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/autoCompleteValues?refName=${refName}${dependees? `&dependees=${dependees}`:''}&text=${text}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getContractors = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number,
  search?: string
): Promise<IPagedCollection<IContractor>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/contractors?pageSize=${pageSize}&pageNo=${pageNo}${search ? `&search=${search}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getContractor = async (
  abortController: AbortController,
  contractorId?: number,
  contractorCode?: string,
): Promise<IContractor> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/contractor?${contractorId ? `contractorId=${contractorId}` : `contractorCode=${contractorCode}`
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getZones = async (
  abortController: AbortController,
  search?: string
): Promise<IZone[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/zones?${search ? `&search=${search}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const editZone = async (
  abortController: AbortController,
  zoneId: string,
  zone: IUpdateZoneRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/zoneById?zoneId=${zoneId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(zone),
  )
}

export const createZone = async (
  abortController: AbortController,
  zone: ICreateZoneRequest
): Promise<ICreateZoneResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/zone`,
    "POST",
    abortController,
    JSON.stringify(zone),
  )
}

export const deleteZone = async (
  abortController: AbortController,
  zoneId: string,
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/zoneById?zoneId=${zoneId}`,
    "DELETE",
    abortController,
  )
}

export const editCategory = async (
  abortController: AbortController,
  categoryId: string,
  category: IUpdateCategoryRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/assetCategory?assetCategoryId=${categoryId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(category),
  )
}

export const createCategory = async (
  abortController: AbortController,
  category: ICreateCategoryRequest
): Promise<ICreateCategoryResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/assetCategory`,
    "POST",
    abortController,
    JSON.stringify(category),
  )
}

export const getAsset = async (
  abortController: AbortController,
  assetId?: number,
  assetCode?: string,
): Promise<IAssetDetails> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/asset?${assetId ? `assetId=${assetId}` : `assetCode=${assetCode}`}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getZoneDetail = async (
  abortController: AbortController,
  zoneId: string
): Promise<IAssetDetails> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/zoneById?zoneId=${zoneId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const editAsset = async (
  abortController: AbortController,
  assetId: number,
  asset: IUpdateAssetRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/assetById?assetId=${assetId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(asset),
  )
}
export const editContractor = async (
  abortController: AbortController,
  contractorId: number,
  contractor: IUpdateContractorRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/contractor?contractorId=${contractorId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(contractor),
  )
}

export const deleteAsset = async (
  abortController: AbortController,
  assetId: number
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/assetById?assetId=${assetId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "DELETE",
    abortController
  );
};

export const getImportAssets = async (
  abortController: AbortController,
): Promise<ILastImport> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/importassets`,
    "GET",
    abortController
  );
};

export const createAsset = async (
  abortController: AbortController,
  data: ICreateAssetRequest
): Promise<ICreateAssetResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/asset`,
    "POST",
    abortController,
    JSON.stringify(data)
  )
}

export const createContractor = async (
  abortController: AbortController,
  data: ICreateContractorRequest
): Promise<ICreateContractorResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/contractor`,
    "POST",
    abortController,
    JSON.stringify(data)
  )
}

export const createWorkOrderAssignment = async (
  abortController: AbortController,
  data: IWorkOrderAssignmentRequest
): Promise<IWorkOrderAssignmentResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/workOrderAssignment`,
    "POST",
    abortController,
    JSON.stringify(data)
  )
}

export const getAssetCategories = async (
  abortController: AbortController,
): Promise<IAssetCategory[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/assetCategories`,
    "GET",
    abortController
  )
}

export const getWorkOrderStatuses = async (
  abortController: AbortController,
): Promise<IWorkOrderStatuses[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/getWorkOrderStatuses`,
    "GET",
    abortController
  )
}
export const getAssetStatuses = async (
  abortController: AbortController,
): Promise<IAssetStatuses[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/getAssetsStatuses`,
    "GET",
    abortController
  )
}

export const getScheduledUpdateAssetStatus = async (
  abortController: AbortController,
  assetId?: number,
  assetCode?: string
): Promise<IScheduledAction[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/scheduledUpdateAssetStatus?${assetId ? `assetId=${assetId}` : `assetCode=${assetCode}`}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  )
}

export const scheduleUpdateAssetStatus = async (
  abortController: AbortController,
  data: IScheduleActionRequest,
  assetId?: number,
  assetCode?: string,
): Promise<IWorkOrderAssignmentResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/scheduleUpdateAssetStatus?assetId=${assetId ? `assetId=${assetId}` : `assetCode=${assetCode}`}`,
    "POST",
    abortController,
    JSON.stringify(data)
  )
}

export const cancelScheduledUpdateAssetStatus = async (
  abortController: AbortController,
  scheduleActionId: number,
): Promise<string> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/scheduleUpdateAssetStatus?scheduleActionId=${scheduleActionId}`,
    "DELETE",
    abortController,
  )
}

export const importAssets = async (
  abortController: AbortController,
  clearChoice: boolean,
  seed: number
): Promise<IImportResult> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/importAssets`,
    "POST",
    abortController,
    JSON.stringify({
      reset: clearChoice,
      seed: seed,
    })
  );
};

export const previewImportAssets = async (
  abortController: AbortController,
): Promise<IImportedAsset[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/importAssetsPreview`,
    "GET",
    abortController
  );
};

export const getImage = async (
  abortController: AbortController,
  imageUrl: string,
  container?: string,
): Promise<IImage> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/image?imageUrl=${imageUrl}${container ? `&container=${container}` : ''}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getWorkOrders = async (
  abortController: AbortController,
  pageSize: number, pageNo: number): Promise<IPagedCollection<IWorkOrder>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/workOrders?pageSize=${pageSize}&pageNo=${pageNo}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const searchWorkOrders = async (
  abortController: AbortController,
  search: string): Promise<IWorkOrder[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/searchWorkOrders?search=${search}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getWorkOrdersOfIssue = async (
  abortController: AbortController,
  issueId: number): Promise<IWorkOrder[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/workOrdersOfIssue?issueId=${issueId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const updateWorkOrderStatus = async (
  abortController: AbortController,
  workOrderCode: string,
  workOrderStatus: string,
  workOrderStatusId: number,
  workOrderDescription: string
) => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/workOrder?workOrderCode=${workOrderCode}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify({
      status: workOrderStatus,
      statusId: workOrderStatusId,
      description: workOrderDescription,
    })
  );
};
export const updateMetadata = async (
  abortController: AbortController,
  entityType: string,
  metadata: IMetadata,
  name?: string,
): Promise<IUpdateMetadataResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/metadata?entityType=${entityType}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify({
      name: name,
      metadata: metadata,
    })
  );
};

export const uploadFile = async (
  abortController: AbortController,
  name: string,
  content: string,
): Promise<IUploadFileResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/file?code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "POST",
    abortController,
    JSON.stringify({
      name: name,
      content: content,
    })
  );
};

export const uploadImage = async (
  abortController: AbortController,
  name: string,
  content: ArrayBuffer,
): Promise<IUploadFileResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/image?code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "POST",
    abortController,
    JSON.stringify({
      name: name,
      content: content,
    })
  );
};

export const resendWorkOrder = async (
  abortController: AbortController,
  workOrderId: number): Promise<IResendWorkOrderResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/resendWorkOrder?workOrderId=${workOrderId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "POST",
    abortController
  );
};

export enum QrCodeEntityTypes {
  Asset,
  Zone,
}

export const getQrCodes = async (
  abortController: AbortController,
  entity: QrCodeEntityTypes,
  pageSize: number,
  pageNo: number
): Promise<IPagedCollection<IQrCode>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/qrcodes/${entity}/?pageSize=${pageSize}&pageNo=${pageNo}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getQrCodesDoc = async (
  abortController: AbortController,
  entity: QrCodeEntityTypes,
): Promise<Response> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/qrcodesdoc/${entity}`,
    "GET",
    abortController,
    undefined,
    true
  );
};

export const getQrCodesPng = async (
  abortController: AbortController,
  entity: QrCodeEntityTypes,
): Promise<Response> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/qrcodespng/${entity}`,
    "GET",
    abortController,
    undefined,
    true
  );
};

export const getLogFile = async (
  abortController: AbortController,
  path: string,
): Promise<Response> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/file?filePath=${path}`,
    "GET",
    abortController,
    undefined,
    true
  );
};

export const uploadAssetCategories2IssueTypes = async (
  abortController: AbortController,
  request: IAssetCategories2IssueTypesRequest,
): Promise<IAssetCategories2IssueTypesResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/importAssetCategories2IssueTypes`,
    "POST",
    abortController,
    JSON.stringify(request)
  );
};

export const uploadZones = async (
  abortController: AbortController,
  request: IImportZoneRequest,
): Promise<IImportZonesResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/importZones`,
    "POST",
    abortController,
    JSON.stringify(request)
  );
};

export const getAssetCategoryIssueTypes = async (
  abortController: AbortController,
  assetCategoryId: string
): Promise<IIsueType[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/assetCategoryIssueTypes?assetCategoryId=${assetCategoryId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getReports = async (
  abortController: AbortController,
): Promise<IReport[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/reports?code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getSettings = async (
  abortController: AbortController,
): Promise<ISettings> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/config`,
    "GET",
    abortController
  );
};

export const getBuildNumber = async (
  abortController: AbortController,
): Promise<string> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/buildNumber`,
    "GET",
    abortController
  );
};

export const chat2Command = async (
  abortController: AbortController,
  request: IChat2CommandRequest,
): Promise<IChat2CommandResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/convertText`,
    "POST",
    abortController,
    JSON.stringify(request)
  );
};