import { CommandBar, Text, DetailsListLayoutMode, ICommandBarItemProps, IconButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react'
import { useContext, useEffect, useState } from 'react'
import AppContext from '../components/AppContext';
import { IPagedCollection } from '../services/assetServices';
import { scrollStackItemStyles } from './styles/StackStyles';
import { useSetAtom } from 'jotai';
import { errorMessageAtom, isInProgressAtom } from '../atoms/messageBarAtoms';

export interface IListPageColumn {
  key: string;
  minWidth?: number;
}
export interface IListPageParams<T> {
  selectedTab: string;
  columns: IListPageColumn[];
  commandBarItems: ICommandBarItemProps[];
  getAction: (abortController: AbortController, pageSize: number, pageNo: number, orderBy?: string, search?: string) => Promise<IPagedCollection<T>>;
}

function PagedList<T>(params: IListPageParams<T>) {
  const context = useContext(AppContext);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const [isLastPage, setIsLastPage] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState({ no: 1, search: "", filters: "" });
  const [orderBy, setOrderBy] = useState<string>();
  const [refreshCount, setRefreshCount] = useState(0);
  const [shimmered, setShimmered] = useState(false);

  const [items, setItems] = useState<T[]>();

  const fetchData = async () => {
    context.setSelectedTab(params.selectedTab);
    const abortController = new AbortController();
    setIsInProgress(true);
    try {
      const data: IPagedCollection<T> = await params.getAction(abortController, 10, page.no, orderBy, page.search);
      setItems(data?.items);
      setIsLastPage(data?.isLastPage);
      setPageCount(data?.pageCount);
      setShimmered(false);
    }
    catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    }
    finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    fetchData();
  }, [page.no, page.search, page.filters, refreshCount]);

  return (
    <>
      <Stack horizontal verticalAlign="center">
        <CommandBar
          items={params.commandBarItems}
          ariaLabel="Items actions"
          primaryGroupAriaLabel="Items actions"
          farItemsGroupAriaLabel="More actions"
        />
        <SearchBox
          placeholder="Search"
          onSearch={(newValue) => setPage({ ...page, no: 1, search: newValue || "" })}
          onClear={() => setPage({ ...page, no: 1, search: "" })} />
      </Stack>
      <Stack>
        <Stack.Item align="start" styles={scrollStackItemStyles} >
          <ShimmeredDetailsList
            items={items ?? []}
            columns={params.columns.map(c => ({
              key: c.key,
              name: c.key,
              fieldName: c.key,
              minWidth: c.minWidth ?? 100,
              isResizable: true,
            }))}
            setKey="set"
            layoutMode={DetailsListLayoutMode.fixedColumns}
            selectionMode={SelectionMode.single}
            selectionPreservedOnEmptyClick={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="select row"
            enableShimmer={shimmered}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Stack.Item grow={1} align="center">
          <IconButton
            iconProps={{ iconName: "DoubleChevronLeft" }}
            disabled={page.no === 1}
            onClick={() => setPage({ ...page, no: 1 })}
          />
          <IconButton
            iconProps={{ iconName: "ChevronLeft" }}
            disabled={page.no === 1}
            onClick={() => setPage({ ...page, no: page.no - 1 })}
          />
          <Text>
            {page.no} of {pageCount}
          </Text>
          <IconButton
            iconProps={{ iconName: "ChevronRight" }}
            disabled={isLastPage}
            onClick={() => setPage({ ...page, no: page.no + 1 })}
          />
          <IconButton
            iconProps={{ iconName: "DoubleChevronRight" }}
            disabled={isLastPage}
            onClick={() => setPage({ ...page, no: pageCount })}
          />
        </Stack.Item>
      </Stack>
    </>
  );
}

export default PagedList