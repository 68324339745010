import { IStackProps } from "@fluentui/react";

export const labelColumnStyle = {
  width: 125,
  textAlign: 'left' as const
};
export const valueColumnStyle = {
  width: 250,
}
export const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 5 },
  styles: { root: { width: 600 } },
};