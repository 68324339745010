import { Stack } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getAsset, getZoneDetail } from "../services/assetServices";
import BackButton from "../common/BackButton";
import { useSetAtom } from "jotai";
import { errorMessageAtom, isInProgressAtom, successMessageAtom } from "../atoms/messageBarAtoms";

const ShowQR = () => {
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const query = new URLSearchParams(useLocation().search);
  const itemId = Number.parseInt(query.get("itemId") ?? "");
  const zoneId = query.get("zoneId");

  const getImageData = (abortController: AbortController): Promise<string> => {
    if (itemId) {
      return getAsset(abortController, itemId)
        .then((data) => {
          return `data:image/png;base64,${data.qrCode.data}`;
        })
    } else if (zoneId) {
      return getZoneDetail(abortController, zoneId || "")
        .then((data) => {
          return `data:image/png;base64,${data.qrCode.data}`;
        })
    }
    throw new Error();
  }

  useEffect(() => {
    const abortController = new AbortController();
    setIsInProgress(true);
    getImageData(abortController)
      .then((img: string) => {
        setImage(img);
        setSuccessMessage("Download completed");
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage(error.message);
      })
      .finally(() => setIsInProgress(false));

    return () => {
      abortController.abort();
    }
  }, [itemId]);

  const [image, setImage] = useState("");

  return (
    <div>
      <Stack>
        <BackButton backTo={`${itemId ? '/' : '/zones'}`} />
        <div className="ms-Grid">
          <div className="ms-Grid-row">
            <img src={image} alt="" style={{ width: 150 }} />
          </div>
          <div className="ms-Grid-row">
            <span>{itemId ? "Asset" : "Zone"} Id: {itemId ?? zoneId}</span>
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default ShowQR;
