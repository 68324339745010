import {
  Link,
  Label,
  Stack,
  Text,
  TextField,
  IStackTokens,
  DetailsListLayoutMode,
  SelectionMode,
  mergeStyles,
  DetailsList,
  IconButton,
  IColumn,
  getTheme,
  IDetailsListProps,
  DetailsRow,
  IDetailsRowStyles,
  Modal,
  IIconProps,
  PrimaryButton,
  DefaultButton,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  getImage,
  getAsset,
  getWorkOrdersOfIssue,
  IAssetDetails,
  IIssue,
  IWorkOrder,
  IImage,
  IAsset,
  deleteAsset,
} from "../../services/assetServices";
import WorkItems from "../../components/workOrders/WorkItems";
import { labelColumnStyle, valueColumnStyle } from "../../common/styles/FormsStyles";
import { useSetAtom } from "jotai";
import { errorMessageAtom, isInProgressAtom } from "../../atoms/messageBarAtoms";

const DetailAsset = () => {
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const query = new URLSearchParams(useLocation().search);
  const itemId = Number.parseInt(query.get("itemId") ?? "");
  const action = query.get("action");

  const navigate = useNavigate();

  useEffect(() => {
    setIsInProgress(true)
    const abortController = new AbortController();
    getAsset(abortController, itemId)
      .then((data: IAssetDetails) => {
        if (data) {
          if (data.asset) {
            setAsset({
              code: data.asset.code,
              id: data.asset.id,
              name: data.asset.name,
              uuid: data.asset.uuid,
              category: data.asset.category,
              categoryId: data.asset.categoryId,
              supportedIssues: data.asset.supportedIssues
            });
          }
          if (data.issues) {
            setIssues(data.issues);
          }
        }
      })
      .catch((error) => {
        console.log("Error:", error);
        setErrorMessage(error.message);
      })
      .finally(() => setIsInProgress(false));
    return () => {
      abortController.abort();
    }
  }, [itemId]);

  const [asset, setAsset] = useState<IAsset>();


  const [workOrders, setWorkOrders] = useState<IWorkOrder[]>([]);
  const [issues, setIssues] = useState<IIssue[]>([]);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [currentIssue, setCurrentIssue] = useState(0);

  const handleClickOnImageLink = (issue: IIssue | undefined) => {
    if (!issue) {
      return;
    }
    const abortController = new AbortController();

    getImage(abortController, issue.imageUrl)
      .then((data: IImage) => {
        const index = issues.indexOf(issue);
        issues[index].image = data.imageContent;
        setIssues(issues);
        setCurrentIssue(index);
        showModal();
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage(error.message);
      });
  };

  const handleClickOnWorkOrdersLink = (issueId: number | undefined) => {
    if (!issueId) {
      return;
    }
    setIsInProgress(true);
    setShimmered(true);
    const abortController = new AbortController();
    getWorkOrdersOfIssue(abortController, issueId)
      .then((data: IWorkOrder[]) => {
        const filteredData = workOrders.filter((wo) => wo.issueId !== issueId);
        setWorkOrders(filteredData.concat(data));
        setShimmered(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage(error.message);
        setShimmered(false);
      })
      .finally(() => setIsInProgress(false));
  };

  const handleClientOnDeleteYes = () => {
    if (!itemId) {
      return;
    }
    const abortController = new AbortController();
    deleteAsset(abortController, itemId)
      .then(() => { navigate("/") })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage(error.message);
      })
  }

  const stackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10,
  };

  const [shimmered, setShimmered] = useState(false);

  const itemClass = mergeStyles({
    selectors: {
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  });

  const _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.itemIndex % 2 === 0) {
        // Every other row renders with a different background color
        customStyles.root = { backgroundColor: theme.palette.themeLighterAlt };
      }
      const issue = issues?.find((iss) => iss.id === props.item.id);
      const issueWorkOrders = workOrders.filter(
        (wo) => wo.issueId === issue?.id
      );
      return (
        <Stack>
          <DetailsRow {...props} styles={customStyles} />
          <WorkItems
            issueWorkOrders={issueWorkOrders}
            issue={issue}
            shimmered={shimmered}
          />
        </Stack>
      );
    }
    return null;
  };

  const issuesColumns: IColumn[] = [
    {
      key: "id",
      name: "Id",
      fieldName: "id",
      minWidth: 30,
      maxWidth: 50,
      isResizable: true,
    },
    {
      key: "comment",
      name: "Comment",
      fieldName: "comment",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "workOrdersCount",
      name: "WorkOrders",
      fieldName: "workOrdersCount",
      minWidth: 80,
      maxWidth: 80,
    },
    {
      key: "image",
      name: "Image",
      fieldName: "image",
      minWidth: 50,
      maxWidth: 50,
    },
  ];

  const _renderItemColumn = (
    item: IIssue,
    index?: number,
    column?: IColumn
  ) => {
    const fieldContent = item[column?.fieldName as keyof IIssue] as string;

    switch (column?.key) {
      case "workOrdersCount":
        return item?.workOrdersCount > 0 ? (
          <Link
            disabled={item?.workOrdersCount === 0}
            onClick={() => handleClickOnWorkOrdersLink(item?.id)}
          >
            {item?.workOrdersCount}
          </Link>
        ) : (
          <span>{item?.workOrdersCount}</span>
        );
      case "image":
        return item.imageUrl ? (
          <Link
            className={itemClass}
            disabled={item?.imageUrl === ""}
            onClick={() => handleClickOnImageLink(item)}
          >
            image
          </Link>
        ) : (
          <span></span>
        );
      default:
        return <span>{fieldContent}</span>;
    }
  };

  const theme = getTheme();
  const iconClass = mergeStyles({
    fontSize: 14,
    height: 18,
    width: 18,
    padding: "8px 10px 0 0",
  });

  const backIconProps: IIconProps = { iconName: "ChromeBack" };

  return (
    <div>
      <Stack
        tokens={stackTokens}
        styles={{
          root: {
            width: 600,
          },
        }}
      >
        <Stack horizontal>
          <IconButton
            iconProps={backIconProps}
            className={iconClass}
            onClick={() => navigate("/")}
          />
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
            <Text variant="xLarge">Properties</Text>
            {action === "delete" ? <>
              <Text variant="mediumPlus">Delete this asset?</Text>
              <PrimaryButton text="Yes" onClick={() => {
                handleClientOnDeleteYes();
              }} />
              <DefaultButton text="No" /></>
              : <></>}
          </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 5 }}>
          <Stack horizontal>
            <Label style={labelColumnStyle}>Code: </Label>
            <TextField style={valueColumnStyle} value={asset?.code} readOnly />
          </Stack>
          <Stack horizontal>
            <Label style={labelColumnStyle}>Id: </Label>
            <TextField style={valueColumnStyle} value={asset?.id.toString()} readOnly />
          </Stack>
          <Stack horizontal>
            <Label style={labelColumnStyle}>Name: </Label>
            <TextField style={valueColumnStyle} value={asset?.name} readOnly />
          </Stack>
          <Stack horizontal>
            <Label style={labelColumnStyle}>UUID: </Label>
            <TextField style={valueColumnStyle} value={asset?.uuid} readOnly />
          </Stack>
          <Stack horizontal>
            <Label style={labelColumnStyle}>Category: </Label>
            <TextField style={valueColumnStyle} value={asset?.category} readOnly />
          </Stack>
          <Stack horizontal>
            <Label style={labelColumnStyle}>Supported Issues: </Label>
            <TextField style={valueColumnStyle} value={asset?.supportedIssues?.map(si => si.name).join(", ")} readOnly />
          </Stack>
        </Stack>
        <Text variant="xLarge">Issues</Text>
        <DetailsList
          items={issues}
          columns={issuesColumns}
          onRenderRow={_onRenderRow}
          onRenderItemColumn={_renderItemColumn}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
        />
      </Stack>
      <Modal isOpen={isModalOpen} onDismiss={hideModal} isBlocking={false}>
        <div>
          <img src={issues[currentIssue]?.image} alt="" />
        </div>
      </Modal>
    </div>
  );
};

export default DetailAsset;
